export const locale = {
  lang: 'en',
  data: {
    HEADER: {
    },
    WIDGET: {
      CHAT_BOT: 'Chatbot',
      NOTIFICATIONS: 'Notifications',
      SMS: 'SMS Messages',
    },
    SHARED: {
      READ_MORE: 'Read More',
      SEARCH: 'Search',
      DATE: 'Date',
      NEW: 'New',
      Interested: "I'm Interested",
      All_Jobs: 'View All Jobs',
      Feedback: 'Feedback',
      Locations: 'Locations',
      Faq: "FAQ's",
      view_Faq: "View All FAQ's",
      VIEW: "View",
      SUBMIT: "Submit",
      APPLY_ALL: 'Apply All',
      FROM: 'From',
      TO: 'To',
      FILTER: 'Filter',
      KEYWORD: 'Keyword',
      HOME: 'Home',
      LAST_UPDATE: 'Published',
      VIEW_ALL: 'View All',
      CHOOSE_ONE: 'Choose One',
      CHOOSE_Services: 'Choose Services',
      VIEW_MORE: 'View More',
      LEARN_MORE: 'Learn more',
      ADDRESS: 'Address',
      PHONE: 'Phone',
      FAX: 'Fax',
      EMAIL: 'E-mail',
      HOW_HELP: 'How we can help you?',
      WORKING: 'Working at WOQOD',
      WITH_GAS: 'was GAS',
      Proceed: 'Proceed To Payment',
      LOAD: 'Load More',
      LESS: 'Show Less',
      NO_DATA: 'There is No Data',
      VALID: 'Valid Until:',
    },
    HERO_HOME_PAGE_BOTTOM: {
      FUEL_PRICES: 'Fuel Prices',
      EVERYTHING_WOQOD_DOES_INTERNALLY_OR_EXTERNALLY: 'Everything WOQOD does internally or externally.',
    },
    TENDER: {
      SUBSCRIBR_TO_ALERTS: 'Subscribe To Alerts',
    },
    CURRENCIES: {
      QR: 'QR'
    },
    E_SERVICES_SECTION: {
      WOQOD_E_SERVICES: 'WOQOD e-Services',
      FAHES_E_SERVICES: 'FAHES e-Services',
      PRODUCT_SERVICES: 'Products and Services',
      HEART_OF_WOQOOD: "The heart of WOQOD's business is our fuels.",
      TENDERS: "Tenders",
      WHISTLE: 'Whistle Blowing',
      SERVICES: 'Services',
      ONLINE_INSPECTION_REPORT: 'Online Inspection Report'
    },
    PROMOTIONS: {
      PROMOTIONS: 'Promotions',
      VIEW_ALL_PROMOTIONS: 'View all Promotions',
      VIEW_ALL: 'View all ',
      RELATED_PROMOTIONS: 'Related Promotions',
      OFFERS_AND_PROMOTIONS: 'OFFERS AND PROMOTIONS',
      PROMOTIONS_EXPIRED_ON: 'Promotions Expired on',
      LATEST_PEOMOTIONS: 'Latest Promotions',
    },
    NEWS: {
      WOQOD_NEWS: 'WOQOD News',
      VIEW_ALL_NEWS: 'View all News',
      PRESS_RELEASE_NEWS: 'Press Release & News'
    },
    SOCIAL_WIDGET: {
      CHECK_ALL: 'Check all',
      SOCIAL_ACCOUNTS: 'Social Accounts'
    },
    FEEDBACK: {
      FEILD_CANT: "field can't be empty",
      FULL_NAME: 'Full Name',
      COMPANY: 'Company',
      TITLE: 'Title',
      EMAIL: 'Email Address',
      COMMENT: 'Comment',
      MOBILE_NUMBER: 'Mobile Number',
      CATEGORY: 'Category',
      SECTOR: 'Sector',
      SELECT_SECTOR: 'Select Sector',
      SELECT_CATEGORY: 'Select Category',
      SELECT_DEPATMENT: 'Select Department',
      SELECT_TYPE: 'Select Feedback Types',
      DEPARTMENT: 'Department',
      type: 'Type',
      ATTACHMENT: 'Attachement',
      SERVIECS: 'Services ',
    },
    PETROL_STATION: {
      PETROL_STATION: 'Petrol Station',
      FIND_THE_NEAREST_PETROL_STATION_AND_KNOW_THE_SERVICES_IT_OFFERS: 'Find the nearest petrol station and know the services it offers',
      SELECT_YOUR_PETROL_STATION_TYPE: 'Select Your Petrol Station Type',
      SELECT_YOUR_PETROL_STATION_STATUS: 'Select Your Petrol Station Status',
      SELECT_FROM_LIST_OF_THE_SERVICES: 'Select From List Of The Services',
      FIND_YOUR_PETROL_STATION: 'Find Your Petrol Station',
      CLEAR_SELECTED_FILTERS: 'clear selected filters',
      EXPLORE_MORE_SERVICES: 'Explore more services',
      CLEAR_FILTERS: 'Clear filters',
      RESET_ALL: 'Reset All',
      MARINE_STATION: 'Marine Station',
      MOBILE_STATION: 'Mobile Station',
      ACTIVE: 'Active',
      NEAR: 'Petrol Station Near Me',
      UPCOMING: 'Upcoming',
      SEND: 'Send',
      FUEL: 'Fuel',
      SIDRA: 'Sidra',
      FAHES: 'Fahes',
      ALL_TYPES: 'All Types',
      STAND_ALONE: 'Stand Alone'
    },
    FOOTER: {
      COPY_RIGHT_WITHOUT_YEAR: 'Copyright © {{pageType}} All rights reserved',
      COPY_RIGHT_WITH_YEAR: 'Copyright © {{cYear}}, {{pageType}} All rights reserved'
    },
    SHARE_BREADCRUMB: {
      SHARE_THIS: 'Share this'
    },
    DASHBOARD: {
      HELP_YOU: 'We are always here to help you',
      FIND: 'Find here other options',
      TRANSACTIONS: 'Transactions',
      TOPUP: 'Topup',
      THIS_MOUNTH: 'This month',
      SELECT_CAR: 'Select car',
      NEW_VEHICLE: 'Add New Vehicle',
      UPCOMING: 'Upcoming',
      COMPLETED: 'Completed',
      CANCELLED: 'Cancelled',
      ADD_NEW_APPOINTMENT: 'Add New Appointment',
      STEPS_APPOINTMENT: 'Steps Appointment',
      DASHBOARD: 'Dashboard',
      PROFILE: 'Profile',
      DEACTIVATE: 'Deactivate Account',
      MY_PROFILE: 'My Profile',
      MY_VEHICLE: 'My Vehicle',
      APPOINTMENT_HISTORY: 'Appointment History',
      WALLET: 'Wallet',
      Frequently: 'Frequently asked questions',
      CHAT: 'Chat box',
      CALL: 'Call centre (Offline Support)',
      HELP: 'Need Help?',
      WHATS_APP: 'WhatsApp',
      FACEBOOK: 'Facebook Messenger',
      INSTGRAM: 'Instagram',
      SETTINGS: 'Settings',
      HELP_ASIDE: 'Helps',
      LANG: 'LANGUAGE',
      ADMIN: 'Admin User',
      MODAL_SUB_TITLE: 'All your account related details will be removed.',
      Email: 'Email Address',
      PHONE: 'Mobile',
      OTP_TITLE: 'Get OTP',
      OTP_DESC: 'OTP will send your registered mobile Number',
      NUMBER: 'Cost of Tag + Installation Charge',
      NUMBER_OF_TAG: 'Number of Tags',
      TOTAL: 'Total Price',
      BACK: 'Back',
      SAVE: 'Save & Continue Later',
      SAVE_S: 'Save',
      NEXT: 'Next',
      EDIT: 'Edit',
      RECOMMENDED: 'Recommended size is 256×256px, must be in .jpg or .png',
      ACCOUNT: 'Account Type',
      AGREE: 'I agree with Fahes’s Terms and Conditions',
      AGREE_WOQOD: 'I agree with WOQOD Terms and Conditions',
      BOOKING: 'Booking Confirmation Notification',
      BOOKING_NOTIFICATION: 'Booking Changed Notification',
      ORDER: 'Order Delivered Notification',
      EMAIL: 'Email Notification',
      CURRENT: 'Current Balance',
      NOMINAL: 'Enter the nominal amount minimum QR 100',
      INSTANT: 'Instant',
      PAYMENT: 'Continue To Payment',
      VIEW_PROFILE: 'View Profile',
      MY_WALLET: 'My Wallet',
      LOG_OUT: 'Logout',
      EDIT_PROFILE: 'Edit Profile',
      Actions: 'Actions',
      Deactive: 'Deactive',
      CANCEL: 'Cancel',
      SAVE_CHANGES: 'Save Changes',
      RESONE_FOR_DEACTIVATE: 'Resone for deactivate',
      FAILED: 'Failed',
      NA: 'N/A',
      SUCCESS: 'Success',
    },
    PROMOTION_FILTER: {
      FILTER_PROMOTIONS: 'Filter Promotions',
      LOCATION: 'Location',
      DATE_RANGE: 'Date Range',
      PROMOTION_CATEGORY: 'Promotion Category',
      PROMOTION_STATUS: 'Promotion Status',
      APPLY_FILTER: 'apply filter'
    },
    ABOUT_US: {
      OUR_MISSION: 'OUR MISSION',
      OUR_STORY: 'Our Story',
      READ_FULL_STORY: 'Read Full Story',
      OUR_LEADERSHIP: 'Our Leadership',
      OUR_HERITAGE: 'Our Heritage',
      OUR_VISION: 'OUR VISION',
      OUR_VALUES: 'OUR VALUES',
      PROFESSIONAL: 'Professional',
      RLEATED: 'Related Items',
      RLEATED_CONTENT: 'Related Content',
    },
    FAHES_MEDIA_CENTER: {
      NEWS: 'News & Announcements',
      MULTIMEDIA: 'Multimedia',
      EVENTS: 'Events',
      VIEW: 'View All',
      OUR_LASTEST_NEWS: 'Our Latest News & Announcements',
      OUR_LASTEST_MULTIMEDIA: 'Our Latest Multimedia',
      OUR_LASTEST_EVENTS: 'Our Latest Events',
      READ_MORE: 'Read More',
      READ_LESS: 'Read Less',
      SEARCH: 'Search by keyword',
      FILTER: 'Filter',
      LOAD_MORE: 'Load More',
      MILESTONES: 'MILESTONES',
      OF: 'of',

    },
    PERIOD: {
      PM: 'PM',
      AM: 'AM',
    },
    MONTHS: {
      JAN: 'Jan',
      FEB: 'Feb',
      MAR: 'Mar',
      APR: 'Apr',
      MAY: 'May',
      JUN: 'Jun',
      JUL: 'Jul',
      AUG: 'Aug',
      SEP: 'Sep',
      OCT: 'Oct',
      NOV: 'Nov',
      DEC: 'Dec',

      JANUARY: "January",
      FEBRUARY: "February",
      MARCH: "March",
      APRIL: "April",
      // MAY: "May",
      JUNE: "June",
      JULY: "July",
      AUGUST: "August",
      SEPTEMBER: "September",
      OCTOBER: "October",
      NOVEMBER: "November",
      DECEMBER: "December"
    },
    TRAFFIC_SERVISE: {
      TITLE_STORY: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      BUTTON_STORY: 'MOI',
      STATION: 'Station Map',
      INSURANCE: 'Insurance',
      LOCATION: 'Location',
      STATUS: 'Station status',
      STATION_CONTACT: 'Station Contacts Person Details',
      TYPE: 'Station Type',
      AVAILABLE: 'Available Services',
    },
    SERVICE_INSPESTION: {
      YOUR_BOOKING: 'Your booking Confirmed Successfully',
      CONGRATS: 'Congratulations your tickets are successfully booked',
      ID: 'Booking Confirmation ID:',
      VIEW_SUMMARY: 'View Summary',
      BOOKING_SUMMARY: 'Booking Summary',
      ASSIGN: 'assign A Job',
      FEE: 'Inspection Fee',
      APPOINTMENT: 'Appointment Reference Number ',
      NAME: 'Assigned Name',
      REMOVE: 'Remove Car',
      SELECT: 'Select',
      CANCEL: 'cancel',
      CONFIRM: 'Confirm',
      NO_OF: 'No of vehicle:',
      APPLY: 'Apply for all',
      BACK: 'BACK TO INSPECTION',
      DUE: 'Due Inspection',
      CLEAR: 'Clear All',
      SORT: 'Sort By:',
      ONLY: 'Only one re-inspection is free',
      APPROX: 'Approx Total Price',
      LIST: 'Next Due Inspection vehicles list',
      DESCRIPTION: 'Individual vehicles that are less than 3 years old, they don’t require inspection',
      BOOKING_CONFIREMD: 'Booking Confirmed',
      REFERENCE: 'Booking reference',
      VEHICLE_NAME: 'Vehicle name',
      PLATE: 'Plate',
      AMOUNT: 'Amount',
      DATE: 'Appointment Date & Time',
      CHOOSE: 'Choose your Vehicle',
      DATE_TIME: 'Select Date & Time',
      INSPECTED_DATE: 'Inspected date',
      INSPECTED_STATUS: 'Inspection status',
      DATE_AND_TIME: 'Date & Time',
      PAYMENT: 'Payment Summary',
      NO_OF_CAR: 'No. Of Cars',
      PRICE_SELECTED: 'Price of Selected Vehicle',
      SUB_TOTAL: 'Sub Total',
    },
    MEDIA_TYPES: {
      ALL: 'All',
      VIDEOS: 'Videos',
      GALLERIES: 'Galleries',
      IMAGES: 'Images',
      INFOGRAPHICS: 'Infographics'
    },
    MEDIA_CENTER: {
      NEWS_AND_ANNOUNCMENTS: 'News & Announcements',
      MULTIMEDIA: 'Multimedia',
      EVENTS: 'Events',
      PUBLISHED: 'Published:',
      START_DATE: 'Start Date:',
      END_DATE: 'End Date:',
      RELATED_NEWS: 'Related News',
      RELATED_EVENTS: 'Related Events',
    },
    EVENT: {
      EVENT_CATEGORY: 'Event Category',
      FILTER_THE_EVENT_BY: 'Filter the events by',
      EVENT_INFORMATION: 'Event information',
    },
    MULTIMEDIA: {
      SEARCH_BY_NAME_DESCRIPTION: 'Search by name/description',
      SEARCH_BY_NAME: 'Search by name'
    },
    BREADCRUMB: {
      CORPORATE_SOCIAL_RESPONSIBILITY: 'Corporate Social Responsibility',
      BECOME_A_SUPPLIER: 'Become a Supplier',
      TENDER_REQUEST: 'Tender Request',
      TENDER_ALERT: 'Tender Alert',
      TENDERS_AND_SUPPLIER: 'Tenders and Supplier',
      PAGES: 'Pages',
      SUCCESS: 'Success',
      MEDIA_CENTER: 'Media Center',
      NEWS_AND_ANNOUNCEMENTS: 'News and Announcements',
      EVENTS: 'Events',
      MULTIMEDIA: 'Multimedia',
      VIDEOS: 'Videos',
      GALLERIES: 'Galleries',
      IMAGES: 'Images',
      ENVIRONMENTAL_MANAGEMENT: 'Environmental Management',
      PETROL_STATION_SAFETY_INSTRUCTIONS: 'Petrol Stations Safety Instructions',
      INTEGRATED_MANAGEMENT_SYSTEM: 'Integrated Management System',
      AWARENESS_CAMPAIGN: 'Awareness Campaign',
      BRAND_GUIDELINES: 'Brand Guidelines',
      COMPANY_PROFILE: 'Company Profile',
      HEALTH_AND_SAFETY: 'Health and Safety',
      PROMOTIONS: 'Promotions',
      FAHES: 'Fahes',
      CODE_OF_ETHICS: 'Code Of Ethics-and-Conducts',
      WHY_WOQOD: 'Why Woqod',
      CAREERS: 'Careers',
      WHAT_WE_DO: 'What We Do',
      EQUALITY_DIVERSITY_INCLUSION: 'Equality, diversity & inclusion',
      WHAT_WE_LOOK_FOR: 'What We Look For',
      INTERVIEW_TIPS: 'Interview Tips',
      GET_NOTICED: 'Get Noticed',
      TALENT_DEVELOPMENT_STRATEGY: 'Talent Development Strategy',
      QATARAIZATION: 'Qatarization',
      LIFE_AT_WOQOD: 'Life at Woqod',
      WOQOD_CULTURE: 'Woqod Culture',
      BENEFITS: 'Benefits',
      TOP_TEN_REASON_TO_CHOOSE_WOQOD: 'Top 10 Reason To Choose Woqod',
      MEET_OUR_TEAM: 'Meet Our Team',
      FIVE_WAYS_TO_HELP_YOU_FIND_THE_RIGHT_CAREER_PATH: 'Five Ways To Help You Find The Right Career Path',
      SEARCH_RESULT: 'Search Results',
      FAHES_ABOUT_US: 'about us',
      FAHES_WHAT_WE_DO: 'What We DO',
      FAHES_WHO_WE_ARE: 'Who we are',
      FAHES_CONTACT_US: 'contact us',
      SYSTEMS_AND_REGULATION: 'systems and regulations',
      WOQODE_TAG: 'Woqode Tag',
      RETAIL: 'Retail',
      AUTO_CARE_SERVICE: 'Auto Care Service',
      BUSINESS_WITH_WOQOD: 'Business with woqod',
      FAQ: 'FAQ',
      SUSTAINABILITY: 'Sustainability',
      VISION_MISSION_VALUES: 'Vision Mission Values',
      ABOUT_US: 'About us',
      KENAR_SHOP: 'Kenar Shop',
      SIDRA: 'Sidra',
      TENDER_DETAILS: 'Tender Details',
      TENDER_AND_SUPPLIER: 'Tender and Supplier',
      SHAFAF: 'Shafaf',
      SERVICES: 'Services',
      ERM: 'ERM',
      LUBRICANTS: 'Lubricants',
      CAREER_JOBS: 'Career Jobs',
      LEADERSHIP: 'Our LeaderShip',
      BUSINESS_CONTINUITY_MANAGEMENT: 'Business Continuity Management',
      ENTERPRISE_RISK_MANAGEMENT: 'Enterprise Risk Management',
      Document:'Documents',
      REQUEST_E_TAG:'Request E Tag'
    },
    CAREER_FILTER: {
      KEYWORD_SEARCH: 'Keyword Search',
      SELECT_LOCATION: 'Select Location',
      SELECT_DEPARTMENTS: 'Select Departments',
      FILTER_BY_TAGS: 'FILTER BY TAGS',
      MORE_TAGS: 'MORE TAGS',
      FILTER_BY_SALARY_RANGE: 'FILTER BY Salary RANGE',
      FULL_TIME_ONLY: 'Full time only',
      INSIDE_DOHA_QATAR: 'Inside Doha, Qatar',
      FILTER: 'Filter'
    },
    RANGE: {
      MIN_SALARY: 'Min salary',
      MAX_SALARY: 'Max salary'
    },
    CAREER_LISTING: {
      JOB_ID: 'Job ID',
      INDUSTRY: 'Industry',
      JOB_INFORMATION: 'Job Information',
      JOB_TIME: 'Job Time',
      JOB_LOCATION: 'Current Location',
      CITY: 'City',
      STATE: 'State/Province',
      COUNTRY: 'Country',
      SALARY: 'Salary',
      ZIP: 'Zip/Postal Code',
      REQUIREMENTS: 'Requirements',
      DETAILS: 'Details',
      INTERSTED: 'Im Intersted',
      REQUEST: 'Request A Document',
      CAREER_JOB: 'Job Title',
      SECOND_NAME: 'Second Name',
      NATIONALITY: 'Nationality',
      CV_FILE: 'CV File',
      EDUCATION_DEGREE: 'Educational Qualification',
      FIELD: 'Field Of Study',
      CURRENT: 'Current Employment Status',
      YEARS: 'Years of Experience',
      WORK: 'Current/Previous Work industry',
      EMPLOYER: 'Current/Previous Employer',
      UNVIERSITY: 'Educational Certificate',
      DESC: 'Description',
      JOB_CODE: 'Job Code',
    },
    TENDER_DETAILS: {
      TENDER_INFORMATION: 'Tender Information',
      S_NUMBER: 'Tender Serial Number',
      TENDER_NUMBER: 'Tender Number',
      TEBDER_CATEGORY: 'Tender Category',
      FEE: 'Fee',
      SCOPE: 'Scope Of Work'
    },
    PLURAL: {
      JOB: {
        ZERO: '{{count}} jobs',
        SINGULAR: '{{count}} job',
        DUAL: '{{count}} jobs',
        BETWEEN_THREE_NINE: '{{count}} jobs',
        TEN: '{{count}} jobs',
        MORE_THAN_TEN: '{{count}} jobs'
      },
      DAYS_LEFT: {
        ZERO: '{{count}} Days Left',
        SINGULAR: '{{count}} Day Left',
        DUAL: '{{count}} Days Left',
        BETWEEN_THREE_NINE: '{{count}} Days Left',
        TEN: '{{count}} Days Left',
        MORE_THAN_TEN: '{{count}} Days Left'
      }
    },
    ERRORS: {
      THIS_FIELD_IS_REQUIRED: 'This field is required',
      YOUR_BROWSER_DOES_NOT_SUPPORT_HTML5_VIDEO: 'Your browser does not support HTML5 video.',
      Email: "The email address you entered is invalid",
      CR: 'The CR Number you entered is invalid',
    },
    SIGN_IN: {
      FULL_ADDRESS: 'Full Address',
      SIGN_IN_WOQOD: 'Sign in WOQOD',
      ENTER_MOBILE_NUMBER: 'Enter Mobile Number',
      RESEND_IN: 'Resend in',
      RESEND: 'Resend',
      ENTER_VERIFICATION_CODE: 'Enter Verification Code',
      LOGIN: 'Login',
      OR: 'or',
      CONTINUE_AS_A_GUEST: 'Continue as a guest',
      ENTER_YOUR_PROFILE_DETAILS: 'Enter your profile details',
      FULL_NAME: 'Full Name',
      EMAIL: 'Email',
      ADDRESS: 'Address',
      DATE_OF_BIRTH: 'Date of birth',
      IAM_MAN: "I'm Man",
      IAM_WOMAN: "I'm Woman",
      CONTINUE: 'Continue',
      PROTECTED_BY_RECAPTCHA_AND_SUBJECT_TO_BE: 'Protected by reCAPTCHA and subject to the',
      WOQOD_PRIVACY_POLICY: 'Woqod Privacy Policy',
      AND: 'and',
      TERMS_OF_SERVICE: 'Terms of Service',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      VERIFY: 'Verify',
      QID: 'QID',
      QID_PASSPORT: 'QID/Passport',
      POBOX: 'Po Box',
      COUNTRY: 'Country',
      CITY: 'City',
      STREET: 'Street address',
      GENDER: 'Gender',
      MALE: 'Male',
      FEMALE: 'Female'

    },
    SEARCH: {
      ALL_FAQS: "All FAQ's",
      WRITE_HERE: 'Write here..',
      KEYWORD_SUGGESTIONS: 'keywords Suggestions',
      HISTORY: 'History',
      SEARCH: 'Search',
      SEARCH_RESULT: 'Search results for',
      READ_MORE: 'READ MORE',
      FILTER_BY_CATEGORY: 'Filter by category',
      SELECT_CATEGORY: 'Select cateogry',
      FILTER_BY_TAG: 'Filter by tag'
    },
    SUPPLIER: {
      VIEW_FORM: 'View Form',
      SUBSCRIBE_FOR_THE_TENDER_ALERTS: 'Subscribe for the Tender alerts',
      PLEASE_TICK_THE_BOX_BEFORE_SUBMITTING: 'Please tick the box before submitting',
      COMPANY_INFORMATION: 'Company information',
      VENDOR_NAME: 'Vendor Name',
      VENDOR_NUMBER: 'Vendor Number',
      COMPANY_REGISTRATION_NUMBER_INTERNATIONAL_CORPORATE_ID: 'Company Registration Number/ International Corporate ID',
      COMPANY_REGISTRATION_EXPIRY: 'Company Registration Expiry',
      COMPANY_CATEGORY: 'Company Category',
      COMPANY_SUB_CATEGORY: 'Company Subcategory',
      DAREEBA_TIN: 'Dareeba TIN',
      ICV_CERTIFICATE_NUMBER: 'ICV certificate Number',
      ICV_CERTIFICATE_EXPIRY: 'ICV Certificate Expiry',
      ICV_SCORE: 'ICVScore',
      PRIMARY_CONTACT_NUMBER: 'Primary Contact Number',
      SECONDARY_CONTACT_NUMBER: 'Secondary Contact Number',
      CONFIRM_EMAIL: 'Confirm Email',
      AUTO_CARE_SUPPLIER: 'Auto Care Supplier',
      AUTO_CARE_FOR_CORPORATES: 'Auto Care For Corporates',
      ALL: 'All Category'
    },
    RETAIL: {
      FUEL_RETAIL_PRICES: 'Fuel Retail Prices',
      BZS_LITER: 'Bzs/Liter',
      QR_LITER: 'QR/Liter',
      BECOME_A_SUPPLIER_FOR_SIDRA: 'Become a Supplier for Sidra',
      BECOME_A_SUPPLIER_AUTO_CARE: 'Become a suppler for Auto Care',
      CONTACT_PERSON: 'Contact Person',
      TELEPHONE_NUMBER: 'Telephone Number',
      PHONE_NUMBER: 'Phone Number',
      MAILING_ADDRESS: 'Mailing Address',
      EMAIL: 'Email',
      FAX_NUMBER: 'Fax Number',
      OFFICE_ADDRESS: 'Office Address',
      FOR_ADDITIONAL_INFORMATIONS: 'For Additional Informations',
      VALID_COMPANY_REGISTRATION: 'Valid Company Registration',
      YES: 'Yes',
      NO: 'No',
      SAVE_AND_CONTINUE_LATER: 'Save & Continue Later',
      NEXT: 'Next',
      BACK: 'Back',
      YOUR_REMARKS: 'Your Remarks',
      PRODUCT_CATEGORY: 'Product Category',
      PAYMENT_TERMS_NUMBER_OF_DAYS: 'Payment Terms (Number of days)',
      BOOK_NOW: 'Book Now',
      QID: 'QID',
      VEHICLE_PLATE_TYPE: 'Vehicle plate type',
      VEHICLE_PLATE_NUMBER: 'Vehicle plate number',
      VEHICLE_TYPE: 'Vehicle type',
      TYPE: 'Type',
      SAVED_VEHICLES: 'Saved Vehicles',
      MANUFACTURER_NAME: 'Manufacturer Name',
      PETROL_STATION: 'Petrol station',
      MODEL: 'Model',
      BEST_TIME_TO_CALL: 'Best Time to Call',
      CATEGORY_OF_BUSINESS: 'Category Of Business',
      EXPECTED_DATE_TO_START_BUSINESS_OPERATIONS: 'Expected date to start business operations',
      VAIDATE: 'VALIDATE',
      PAYMENT_TERMS: 'Payment Terms (Number of days)',
      DELIVERY_FREQUENCEY: 'Delivery Frequency',
      ORDERING_LEAD_TIME: 'Ordering Lead Time',
      SCOPE_OF_DELIVERY: 'Scope of Delivery',
      APPROVE_FOR_LISTING: 'Approve for Listing',
      TIME: 'Time',
      PAYMENT: 'Select any payment options to proceed with payment',
      SUBMIT: 'Submit'
    },
    FAQ: {
      FAQS: "FAQ's",
      VIEW_ALL_FAQS: "View All FAQ's",
      ALL_CATEGORY: 'All Category'
    },
    TINDER: {
      VENDOR_NAME: 'Vendor Name',
      ADDRESS: 'Address',
      CONTACT_NAME: 'Contact Name',
      CONTACT: 'Contact#',
      COLLECT_TINDER_DOCUMENT: 'Collect Tinder Document',
      AWARD_TENDERS: 'Award Tenders',
      CLOSED_TENDERS: 'Closed Tenders',
      OPEN_TENDERS: 'Open Tenders',
      SEARCH_BY_AREA_NAME: 'Search by area name',
      SR_NO: 'SR NO',
      TENDER_NO: 'Tender NO',
      DESCRIPTION: 'Description',
      LAST_COLLECTION_DATE: 'Last Collection Date',
      BOND: 'Bond',
      FEE: 'Fee',
      CATEGORY: 'Category',
      REQUEST: 'Request',
      VIEW_ALL_TINDERS: 'View All Tenders',
      ACTIVE_TINDER: 'Active Tenders',
      TENDER_TITLE: 'Tender Title',
      SERIEL_NUMBER: 'Seriel Number',
      INFORMATION: 'Information',
      SUCCESSFUL: 'Successful bidder',
      DETAIL: 'View Details',
      CLOSING_DATE: 'Close Date',
      View_DETAIL: 'View Details',
      BID_CLOSING_DATE: 'Bid Closing Date',
    },
    TIMELINE: {
      HOW_TO_USE: 'Know How To Use'
    },
    TABS: {
      INDIVIDUAL: 'Individual',
      CORPORATE: 'Corporate',
      PRODUCTS: 'Products',
      SERVICES: 'Services',
    },
    PRICE_TABLE: {
      PRICE_TABLE: 'Price Table',
      SEE_PRICES: 'See Prices',
      PER_TAG: 'PER TAG'
    },
    BUSINESS: {
      INITIATE_CONTRACT: 'Initiate Contract'
    },
    PRICES: {
      PRICES: 'Prices',
      VIEW_ALL_PRICES: 'View All Prices',
      BOOK_AN_APPOINTMENT: 'Book An Appointment',
      PRICE_LIST: 'Price List',
      PRICE_IN: 'Price in'
    },
    STEPS: {
      SHOP_INFORMATION: 'Shop Information',
      PERSONAL_INFORMATION: 'Personal Information',
      BUSINESS_INFORMATION: 'Business Information',
      ATTACHMENTS: 'Attachments',
      SUBMISSION: 'Submission',
      GENERAL_DETAILS: 'General Details',
      VEHICLE_DETAILS: 'Vehicle Details',
      BOOK_APPOINTMENT: 'Book Appointment',
      TERMS_AND_CONDITIONS: 'Terms & Conditions',
      PAYMENT: 'Payment',

    },
    E_TAG: {
      WALLET_LIMIT: 'Wallet Limit',
      REQUEST_A_NEW_E_TAG: 'Request A New E-Tag',
      IF_YOU_HAVE_ALREADY_INSTALLED_A_TAG_AND_WANT_TO_REPLACE_IT_KINDLY: 'If you have already installed a tag and want to replace it,kindly',
      CLICK_HERE: 'click here',
      NAME: 'Name',
      QID: 'QID',
      INVOICE: 'Invoice',
      TRACKING: 'Tracking',
      EMAIL: 'Email',
      MOBILE_NUMBER: 'Mobile Number',
      NATIONAL_ADDRESS: 'National Address',
      ZONE_NUMBER: 'Zone Number',
      STREET_NUMBER: 'Street Number',
      BUILDING: 'Building',
      UNIT: 'Unit',
      PO_BOX: 'PO Box',
      FAX_NUMBER: 'Fax Number',
      VEHICLE_DETAILS: 'Vehicle Details',
      VEHICLE_STATUS: 'Vehicle Status',
      MODEL: 'Model',
      MODEL_YEAR: 'Model Year',
      MANUFACTURE_TO_BE_BRAND: 'Manufacture to be (Brand)',
      EXPIRY_DATE: 'Expiry Date',
      SEQUENCE_NUMBER: 'Sequence Number',
      INSURANCE_COMPANY_NAME: 'Insurance Company Name',
      START_DATE: 'Start Date',
      REGISTRATION: 'Registration',
      STATUS: 'Status',
      VEHICLE_ID_NUMBER: 'Vehicle ID Number',
      INSURANCE_EXPIRATION_DATE: 'Insurance Expiration Date',
      PHONE_NUMBER: 'Phone Number',
      SAVE_VEHICLE: 'Save Vehicle',
      NUMBER_OF_VEHICLES: 'Number of Vehicles',
      NUMBER_OF_TAGS: 'Number of Tags',
      TOTAL_PRICE: 'Total Price',
      ADD_BENEFICIARY: 'Add Beneficiary',
      CONFIRM: 'Confirm',
      SELECT_CAR: 'Select Car',
      ADD_A_NEW_CAR: 'Add a New Car',
      CAR: 'Car',
      SEARCH_BY_NUMBER_PLATE: 'Search by number plate',
      RE_INSPECTION: 'Re - Inspection',
      ADD: 'Add',
      DETAILS: 'Details',
      QID_CR: 'QID/CR',
      UPLOAD_FILE: 'Upload File',
      ESTIMARA: 'ESTIMARA',
      INSTALLATION_COST: 'Installation cost',
      TAG_ID: 'Tag ID',
      TAG_FEE: 'Tag Fee',
      COST_OF_INSTALLATION: 'Cost of installation',
      TOTAL: 'Total',
      BOOK_DETAILS: 'Book Details',
      PAYMENT_METHOD: 'PAYMENT METHOD',
      CREDIT_CARD: 'Credit Card',
      KFAST: 'KFAST',
      AMEX: 'AMEX',
      OTHER_METHODS: 'OTHER METHODS',
      CREDIT_LIMIT: 'Credit Limit',
      NET_BANKING: 'Net Banking',
      E_Wallet: 'E - Wallet',
      APPLE_WALLET: 'Apple wallet',
      NEW_CARD: 'New Card',
      NAME_ON_THE_CARD: 'Name on the Card',
      CARD_NUMBER: 'Card Number',
      VALID_TILL: 'Valid Till',
      CVV: 'CVV',
      COST_OF_TAG: 'Cost of Tag + Installation Charge',
      PLATE_NUMBER: 'Plate Number',
      PLATE_TYPE: 'Plate Type',
      TRANSACTION: 'Transaction Limit (QRA or liter)*',
      FUEL_TYPE: 'Fuel Type *',
      FUEL_LIMIT: 'Fuel Limit Duration *',
      MAJOR: 'Major Color',
      VEHICLE_INFORMATION: 'Vehicle Information',
      VEHICLE_INSURANCE: 'Vehicle Insurance Details',
      PHONE: 'Phone Number *',
      FAMILY: 'Family relation between customer account and vehicle owner',
      NUMBER_PLATE: 'Number plate',
      MANUFACTOR: 'Manufactor',
      SELECT_A_BENEFICIARY: 'Select a Beneficiary',
      ADD_NEW_BENEFICIARY: 'Add a New Beneficiary',
      DAYS: 'Days Remaining',
      BOOKED_ON: 'Booked on',
      BOOKED_DETAILS: 'Booking Details',
      Name_AR: 'Name (AR)',
      NUMBER_OF_TAG: 'Number Of Tags',
      BY_NAME: 'Search by Service Name',
      SERVICE_NAME: 'Service Name',
      STATION: 'Station',
      Max: 'Maximum Size',
      MB: 'MB'
    },
    SHOP: {
      BACK: 'Back',
      SUBMIT: 'Submit',
      VEHICLE_TYPE: 'Vehicle type',
      EXISTING_TENANT: 'Existing Tenant',
      I_ACCEPT_AND_AGREE_TO_THE_FOLLOWING_TERMS_AND_CONDITIONS: 'I accept and agree to the following terms & Conditions:',
      RENT_A_SHOP: 'Rent a Shop',
      BACK_TO_AVAILABLE_SHOPS_PAGE: 'BACK TO AVAILABLE SHOPS PAGE',
      DEADLINE: 'Deadline',
      LOCATION: 'Location',
      FIRST_NAME: 'First Name',
      STATION_NAME: 'Station Name',
      NUMBER_APPLICANTS: 'Number of applicants as of now',
      SHOP_PRE: 'Shop rent per month (QR) per square meter',
      SHOP_SIZE: 'Shop Size sqm',
      NUMBER_OF_SHOPS: 'Number of shops required *',
      OPTION: 'Opitonal',
      LIST_LOCATIONS_IF_ANY: 'List Locations (if any)',
      REQUIRED_INFORMATION: 'Required Information',
      NEXT: 'Next',
      MIDDLE_NAME: 'Middle Name',
      LAST_NAME: 'Last Name',
      MOBILE_NUMBER: 'Mobile Number',
      OFFICE_TELEPHONE_NUMBER: 'Office Telephone Number',
      EMAIL: 'Email',
      COMPANY_NAME: 'Company Name',
      DATE: 'Date',
      WHY_WOQOD_SHOULD_CONSIDER_YOU_FOR_LEASING_A_SHOP: 'Why WOQOD should consider you for leasing a shop?',
      WHAT_ADDITIONAL_VALUE_PRODUCTS_SERVICES_YOUR_BUSINESS_WILL_OFFER_TO_WOQOD_CUSTOMERS: 'What additional value (products / services)<br/>your business will offer to WOQOD customers?',
      WILL_YOU_HAVE_A_PARTNER: 'Will you have a Partner?',
      COMPANY_TURNOVER_IN_QATAR: 'Company Turnover in Qatar',
      NUMBER_OF_EMPLOYEES: 'Number of Employees',
      VALIDATE: 'Validate',
      CR_NUMBER: 'CR Number',
      NUMBER_OF_BRANCHES_IN_GSS: 'Number of Branches in GCC',
      NUMBER_OF_BRANCHES_IN_QATAR: 'Number of Branches in Qatar',
      TERMS_AND_CONDITIONS: 'Terms and conditions',
      TERMS_AND_CONDITIONS_FOR_BALLOTING: 'Terms and conditions for Balloting',
      TERMS_AND_CONDITIONS_FOR_PROPERTY_RENTALS: 'Terms and conditions for Property Rentals',
      PAYMENT_SUCCESSFUL: 'Payment Successful',
      FUEL_TYPE_CHANGED: 'Fuel Type Changed',
      LIMITED_HOURS: 'Limited Hours',
      FRANCHISE_COMPANY: 'Franchise Company',
      COMMERCIAL_REGISTRATION_CR_COPY: 'Commercial Registration (CR copy)',
      COPY_OF_COMPUTER_CARD_OR_TRADING_LICENSE: 'Copy of Computer Card or Trading License',
      BANK_ACCOUNT_DETAILS_IN_THE_COMPANY_LETTERHEAD: 'Bank Account Details in the Company Letterhead',
      LETTER_FROM_BANK_CONFIRMING_THE_ACCOUNT: 'Letter from bank confirming the account',
      COPY_OF_OWNERS_ID: 'Copy of Owner’s ID',
      COMPANY_PROFILE: 'Company Profile',
      BRANDING_PROFILE: 'Branding profile',
      I_AGREE: 'I agree',
      START_DATE: 'Start date',
      END_DATE: 'End date',
      SUPPLIER_NAME: 'Supplier Name',
      CONTACT_PERSON: 'Contact Person',
      TRADE: 'Trade License',
      TAX_CARD: 'Tax Card',
      AUTO_CARE: 'Auto Care Services',
      PRODUCT: 'Product description',
      INSURANCE_COMPANY: 'Insurance Company List',
      INSURANCE: 'Insurance Company',
      CR_START_DATE: 'CR Start date',
      CR_END_DATE: 'CR End date',
    },
    UPLOADER: {
      ATTACH: 'Attach'
    },
    DAYS: {
      MON: "Mon",
      TUE: "Tue",
      WED: "Wed",
      THU: "Thu",
      FRI: "Fri",
      SAT: "Sat",
      SUN: "Sun"
    },
    INSPECTION_FILTER: {
      FULL_DAY_FULL_WEEK: '24 hours / 7 days a week',
      CHOOSE_STATION: 'CHOOSE STATION',
      CHOOSE_ANY: 'CHOOSE ANY',
      CHOOSE_STATUS: 'CHOOSE STATUS',
      SELECT_YOUR_PETROL_STATION_TYPE: 'Select Your Petrol Station Type',
      SELECT_YOUR_PETROL_STATION_STATUS: 'Select Your Petrol Station Status',
      SELECT_BY_SERVICE_AVAILABLE: 'Select By Service Available',
      SEARCH_LOCATIONS: 'Search Locations',
      EG: 'Eg',
      WORKING_HOURS: 'Working Hours',
      SORT_BY: 'Sort by',
      SORT_BY_NEAREST_STATIONS: 'Sort by nearest stations',
      OPEN_24_HOURS: 'Open 24 hours',
      DIRECTION: 'Direction',
    },
    TIME: {
      CHOOSE_TIME: 'Choose time'
    },
    WOQODE_TAG: {
      STEP: 'Step',
      HOW_TO_TOP_UP: 'How to top up?',
    },
    KENAR: {
      DEADLINE: 'Deadline',
      RENT_SHOP: 'Rent Shop',
      SEARCH_BY_TITLE: 'Search by Title',
      SUBMIT_INTEREST_IN_LEASING_A_SHOP: 'Submit Interest In Leasing A Shop'
    },
    SIDRA: {
      VISIT_SIDRA_WEBSITE: 'Visit Sidra Website',
      SHOP_NOW: 'Shop Now'
    },
    RENT_SHOP_TERMS_CONDITION: {
      CHECKBOX1: 'I (applicant) will submit refundable Managerial cheque / (Bank guarantee) amounting to Qr.10,000 /- for each shop, issued in favor of WOQOD at (WOQOD Tower, West Bay, 16th Floor, Retail Department) within 3 working days from the day of application submission, for participation in shop balloting process. WOQOD will have absolute right to forfeit security amount, in case the applicant refuses to take allotted shops.',
      CHECKBOX2: 'The applicant will have no right to sub-lease the kenar shop.',
      CHECKBOX3: 'The applicant will not be allowed to change ownership or nature of business.',
      HOMEPAGE: 'To proceed recharge you agree the Terms and Conditions',
    },
    PRODUCT_AND_SERVICE: {
      // LUBRICANTS: 'Lubricants',
      WOQODE: 'Service',
      FUEL: 'Fuel & Retail',
      WAQOD: 'Auto Care',
      READ_MORE: 'READ MORE',
      KINAR: 'Kinar',
      SIDRA: 'Sidra',
      SHAFAF: 'Shafaf',
      BULK: 'Bulk Fuel',
      FAHES: 'FAHES Vehicle Inspections',
      DISTRIBUTION: 'Fuel Distribution',
      GAS: 'Gas Distribution',
      AVIATION: 'Aviation Supply',
      LUBRICANTS: 'WOQOD Lubricants',
      VIEW_OTHER_PRODUCTS: 'View Other Products',
      ENTER_YOUR_QUERIES: 'Enter Your Queries',
      FOR_MORE_INFORMATION: 'For more information'
    },
    SHAFAF: {
      RATES: 'rates',
      LPG_ACCESSORIES: 'lpg accessories',
      VISIT_SHAFAF: 'Visit shafaf Website',
      WHERE_TOBUY_SHAFAF: 'Where To Buy Shafaf',
      VIEW: 'View List',
    },
    WOQOD_APP: {
      APP: 'E-services available on Mobile application to fulfill all marketplace service needs!',
      DOWNLOAD: 'Download WOQOD App Now!!'
    },
    FUELMODAL: {
      TITLE: 'Fuel Price for the year',
      MONTH: 'Month',
      GAS_SUPER: 'Gasoline Super (95)',
      GAS_PRE: 'Gasoline Premium (91)',
      DIESEL: 'Diesel',
    },
    MODALWOQOODE: {
      TITLE: 'Services',
      INDIVIDUAL: 'Individual Customers',
      PRICE: 'Price in QAR',
      LEVEL: 'Level',
      CLICKC: 'Click here For WOQODE Corporates',
      CLICKI: 'Click here For WOQODE Individual',
      CORPORATE: 'Corporate Customers',
      TAGS: 'Tags',
      FROM: 'From',
      TO: 'To',
    },
    FILTER_DDL: {
      CONTACT_NUMBER: 'Sort By Contact Number Asc',
      CONTACT_NUMBER_DESC: 'Sort By Contact Number Desc',
      TITLE: 'Sort By Title',
      STATION: 'Sort By Station Asc',
      STATION_DESC: 'Sort By Station Desc',
      ADDRESS: 'Sort By Address Asc',
      ADDRESS_DESC: 'Sort By Address Desc',
      CONTACT_NAME: 'Sort By Contact Name Asc',
      CONTACT_NAME_DESC: 'Sort By Contact Name DESC',
      TITLE_DESC: 'Sort By Title Desc',
      TITLE_ASC: 'Sort By Title Asc',
    },
    TENDER_MODAL: {
      CR_CERTIFICATE: 'CR CERTIFICATE',
      ADDRESS: 'National Address',
    },
    PLACEHOLDER: {
      DECLARATION: 'Declaration',
      PRODUCT_DESCRIPTION: 'Product Description',
      QID: 'ex: 1234567890',
      EMAIL: 'ex: John@gmail.com',
      SUPPLIER_NAME: 'ex: AIN Snan Petrol Staion',
      CONTACT_PERSON: 'ex: John Samuel',
      CR_NUMBER: 'ex: 22445',
      S_DATE: 'ex: 20 / 2 / 2020',
      E_DATE: 'ex: 20 / 2 / 2020',
      F_NAME: 'ex: John',
      M_NAME: 'ex: Samuel',
      L_NAME: 'ex: Johnne',
      MOBILE: 'ex: +(947) 45678967',
      COMPANY_NAME: 'ex : WOQOD company',
      COMPANY_NAME_AR: ' شركة وقود',
      NUMBER: 'ex : 20',
      NUMBER_GCC: 'ex : 68',
      EMPLOYE_NUMBERR: 'ex : 368',
      LIST_LOCATIONS: 'Enter List Locations (if any)',
      CUSTOMER: 'Enter details about the additional value, products, or services your business plans to offer to WOQOD customers.',
      LEASING: 'Please provide your reasons why WOQOD should consider you for leasing a shop.',
      TURNOVER: "Enter your company's turnover in Qatar (QAR)*",
      NATIONALTIY: 'ex: Qatari',
      EDUCATION: "ex: Bachelor's Degree",
      FIELD: 'ex: Computer Sciences',
      CURRENT: 'ex: Employed',
      YEARS: 'ex: 7',
      INDUSTRY: 'ex: Manufacturing',
      EMPOYLER: 'ex: ABC Company',
      FULL_NAME: 'ex: John Samuel',
      FEEDBACK_TITLE: 'ex: Your Feedback Title',
      FEEDBACK_TITLE_AR: 'ex: Your Feedback Title In Arabic',
      COMPANY: 'ex: WOQOD company',
      COMMENTS: 'Enter your comments here...',
      ZONE: 'Enter Zone Number (ex: 123)',
      BUILDING: 'Enter Building Name or Number',
      UNIT: 'Enter unit Name or Number',
      NAME_EN: 'John Samuel',
      NAME_AR: 'جون صموئيل',
      ENTER_LOCATION: 'Enter a Location',
      SHOW_ALL: 'Show All',
      ACTIVE_ONLY: 'Active only',
      NEARSET: 'Nearest',
      NEWLY_ADDED: 'Newly added',
      BY_STATION: 'Search by Station Name',
      DEADLINE: 'DeadLine Asc',
      LASTEST_ADDED: 'Latest Added',
      SIZE_ASC: 'Size Asc',
      SIZE_DESC: 'Size Desc',
      DATE_FROM: 'From Date',
      DATE_TO: 'To Date',
      CORORATE_ID: 'Enter Company Registration Number/International Corporate ID',
      CERTEFICATE_NUMBER: 'ex: ABC-9876543',
      ICV_SCORE: 'ex: 48 points',
      DATE: 'DD-MM-YYYY',
      COUNTRY: 'Country',
      GENDER: 'Mr / Ms',
      TITLE: 'Title'
    },
    PAYMENT: {
      ID: 'Transaction ID',
      DATE: 'Transaction Date',
      METHODS: 'Payment Methods',
      TOTAL: 'Total Topup Amount',
      CONGRATS: 'Congratulations',
      INTO_WALLET: 'added into your wallet',
      YOU_HAVE: 'You’ve',
      AVAILABLE: 'available in the Wallet',
      PAYMENT_FAILED: "We couldn't process your payment"
    },
    INVESTOR: {
      RELATIONS: 'investor relations',
      EXECUTIVE_MANAGEMENT: 'Executive Management',
    }
  }
};
