export const locale = {
  lang: 'ar',
  data: {
    HEADER: {
    },
    WIDGET: {
      CHAT_BOT: 'شات بوت',
      NOTIFICATIONS: 'إشعارات',
      SMS: 'رسائل SMS',
    },
    SHARED: {
      READ_MORE: 'قراءة المزيد',
      SEARCH: 'بحث',
      DATE: 'تاريخ',
      NEW: 'جديد',
      Interested: "مهتم",
      All_Jobs: ' عرض جميع الوطائف',
      Feedback: 'رأيك',
      Locations: 'المواقع',
      Faq: "الأسئلة الشائعة",
      view_Faq: "عرض جميع الأسئلة",
      SUBMIT: "إرسال",
      VIEW: "عرض",
      APPLY_ALL: 'تقديم الكل',
      FROM: 'من',
      TO: 'إلى',
      FILTER: 'تصفية',
      KEYWORD: 'الكلمة الرئيسية',
      HOME: 'الرئيسيه',
      LAST_UPDATE: 'تاريخ النشر',
      VIEW_ALL: 'عرض الجميع',
      CHOOSE_ONE: 'اختر واحدا',
      CHOOSE_Services: 'اختر الخدمات',
      VIEW_MORE: 'المزيد',
      LEARN_MORE: 'المزيد',
      ADDRESS: 'عنوان',
      PHONE: 'هاتف',
      FAX: 'فاكس',
      EMAIL: 'بريد إلكتروني',
      HOW_HELP: 'كيف يمكننا مساعدتك؟',
      WORKING: 'العمل في وقود',
      WITH_GAS: 'مع غاز',
      Proceed: 'الشروع في دفع',
      LOAD: 'تحميل المزيد',
      LESS: 'عرض أقل',
      NO_DATA: 'ليس هنالك معلومات',
      VALID: 'صالح لغاية:',
    },
    HERO_HOME_PAGE_BOTTOM: {
      FUEL_PRICES: 'أسعار الوقود',
      EVERYTHING_WOQOD_DOES_INTERNALLY_OR_EXTERNALLY: 'كل ما تفعله وقود داخليًا أو خارجيًا.',
    },
    TENDER: {
      SUBSCRIBR_TO_ALERTS: 'اشترك لتلقي التنبيهات',
    },
    CURRENCIES: {
      QR: 'ر.ق'
    },
    E_SERVICES_SECTION: {
      WOQOD_E_SERVICES: 'خدمات وقود',
      FAHES_E_SERVICES: 'خدمات فاحص',
      PRODUCT_SERVICES: 'منتجات وخدمات',
      HEART_OF_WOQOOD: "قلب عمل وقود هو الوقود الذي ننتجه.",
      TENDERS: "المناقصات",
      WHISTLE: 'كاشف الفساد',
      SERVICES: 'خدمات',
      ONLINE_INSPECTION_REPORT: 'تقرير التفتيش على الانترنت'
    },
    DASHBOARD: {
      HELP_YOU: 'نحن دائما هنا لمساعدتك',
      FIND: 'تجد هنا خيارات أخرى',
      TRANSACTIONS: 'المعاملات',
      TOPUP: 'إشحن',
      THIS_MOUNTH: 'هذا الشهر',
      SELECT_CAR: 'أختر سيارة',
      NEW_VEHICLE: 'إضافة مركبة جديدة',
      UPCOMING: 'القادمة',
      COMPLETED: 'مكتملة',
      CANCELLED: 'ألغيت',
      ADD_NEW_APPOINTMENT: 'إضافة موعد جديد',
      DASHBOARD: 'لوحة التحكم',
      PROFILE: 'الملف الشخصي',
      DEACTIVATE: 'الغاء التفعيل',
      MY_PROFILE: 'ملفي',
      MY_VEHICLE: 'مركبتي',
      STEPS_APPOINTMENT: 'موعد الخطوات',
      APPOINTMENT_HISTORY: 'تاريخ التعيين',
      WALLET: 'المحفظة',
      Frequently: 'أسئلة مكررة',
      CHAT: 'الدردشة',
      CALL: 'مركز الاتصال (الدعم دون اتصال)',
      HELP: 'تحتاج مساعدة؟',
      WHATS_APP: 'واتساب',
      FACEBOOK: 'فيسبوك',
      INSTGRAM: 'انستغرام',
      SETTINGS: 'الأعدادات',
      HELP_ASIDE: 'للمساعدة',
      LANG: 'اللغة',
      ADMIN: 'مستخدم إداري',
      MODAL_SUB_TITLE: 'ستتم إزالة جميع التفاصيل المتعلقة بحسابك.',
      Email: 'الأيميل',
      PHONE: 'الهاتف',
      OTP_TITLE: 'احصل على كلمة مرور لمرة واحدة',
      OTP_DESC: 'سوف يرسل OTP رقم هاتفك المحمول المسجل',
      NUMBER: 'تكلفة العلامة + رسوم التثبيت',
      NUMBER_OF_TAG: 'عدد العلامات',
      TOTAL: 'السعر الكلي',
      BACK: 'خلف',
      SAVE_S: 'حفظ',
      SAVE: 'احفظ واستمر لاحقًا',
      NEXT: 'التالي',
      EDIT: 'تعديل',
      RECOMMENDED: 'الحجم الموصى به هو 256×256 بكسل، ويجب أن يكون بصيغة .jpg أو .png',
      ACCOUNT: 'نوع الحساب',
      AGREE: 'أنا أوافق على شروط وأحكام فاحص',
      AGREE_WOQOD: 'أنا أوافق على شروط وأحكام وقود',
      BOOKING: 'إشعار تأكيد الحجز',
      BOOKING_NOTIFICATION: 'إشعار تغيير الحجز',
      ORDER: 'إشعار تسليم الطلب',
      EMAIL: 'إشعار البريد الإلكتروني',
      CURRENT: 'الرصيد الحالي',
      NOMINAL: 'أدخل المبلغ الاسمي كحد أدنى 100 ريال قطري',
      INSTANT: 'فوري',
      PAYMENT: 'الاستمرار في الدفع',
      VIEW_PROFILE: 'عرض الصفحة الشخصية',
      MY_WALLET: 'محفظتى',
      LOG_OUT: 'تسجيل خروج',
      EDIT_PROFILE: 'تعديل الملف الشخصي',
      Actions: 'أجراءات',
      Deactive: 'غير نشط',
      CANCEL: 'إلغاء',
      SAVE_CHANGES: 'حفظ التغييرات',
      RESONE_FOR_DEACTIVATE: 'سبب إلغاء التفعيل',
      FAILED: 'فشل',
      NA: 'غير متوفر',
      SUCCESS: 'فعال',
    },
    PROMOTIONS: {
      PROMOTIONS: 'العروض',
      VIEW_ALL_PROMOTIONS: 'عرض كل العروض',
      VIEW_ALL: 'عرض الكل',
      RELATED_PROMOTIONS: 'العروض ذات الصلة',
      OFFERS_AND_PROMOTIONS: "العروض والعروض الترويجية",
      PROMOTIONS_EXPIRED_ON: "انتهت العروض في",
      LATEST_PEOMOTIONS: 'أحدث العروض الترويجية',
    },
    NEWS: {
      WOQOD_NEWS: 'أخبار وقود',
      VIEW_ALL_NEWS: 'عرض كل الأخبار',
      PRESS_RELEASE_NEWS: 'بيان صحفي وأخبار'
    },
    SOCIAL_WIDGET: {
      CHECK_ALL: 'تحقق من الكل',
      SOCIAL_ACCOUNTS: 'حسابات التواصل الاجتماعي'
    },
    FEEDBACK: {
      FEILD_CANT: "لا يمكن أن يكون الحقل فارغًا",
      FULL_NAME: 'الاسم الكامل',
      COMPANY: 'شركة',
      TITLE: 'عنوان',
      EMAIL: 'عنوان البريد الإلكتروني',
      COMMENT: 'تعليق',
      MOBILE_NUMBER: 'رقم الهاتف المحمول',
      CATEGORY: 'الفئة',
      SECTOR: 'القطاع',
      SELECT_SECTOR: 'حدد القطاع',
      SELECT_CATEGORY: 'اختر فئة',
      SELECT_DEPATMENT: 'اختر القسم',
      SELECT_TYPE: 'حدد نوع التعليق',
      DEPARTMENT: 'حدد القسم',
      type: ' حدد نوع',
      ATTACHMENT: 'المرفقات',
      SERVIECS: 'خدمات ',
    },
    PETROL_STATION: {
      PETROL_STATION: 'محطة وقود',
      FIND_THE_NEAREST_PETROL_STATION_AND_KNOW_THE_SERVICES_IT_OFFERS: 'ابحث عن أقرب محطة وقود واعرف الخدمات التي تقدمها',
      SELECT_YOUR_PETROL_STATION_TYPE: 'اختر نوع محطة الوقود الخاصة بك',
      SELECT_YOUR_PETROL_STATION_STATUS: 'اختر حالة محطة الوقود الخاصة بك',
      SELECT_FROM_LIST_OF_THE_SERVICES: 'حدد من قائمة الخدمات',
      FIND_YOUR_PETROL_STATION: 'ابحث عن محطة الوقود الخاصة بك',
      CLEAR_SELECTED_FILTERS: 'مسح الفلاتر المحددة',
      EXPLORE_MORE_SERVICES: 'اكتشف المزيد من الخدمات',
      CLEAR_FILTERS: 'مسح الفلاتر',
      RESET_ALL: 'إعادة تعيين الكل',
      MARINE_STATION: 'المحطة البحرية',
      MOBILE_STATION: 'محطة متنقلة',
      ACTIVE: 'نشيط',
      NEAR: 'محطة بنزين بالقرب مني',
      UPCOMING: 'القادمة',
      SEND: 'يرسل',
      FUEL: 'وقود',
      SIDRA: 'سيدرا',
      FAHES: 'فاحص',
      ALL_TYPES: 'جميع الأنواع',
      STAND_ALONE: 'قائمة بذاتها'
    },
    FOOTER: {
      COPY_RIGHT_WITHOUT_YEAR: 'جميع الحقوق محفوظة © وقود',
      COPY_RIGHT_WITH_YEAR: 'جميع الحقوق محفوظة © {{cYear}}, وقود'
    },
    SHARE_BREADCRUMB: {
      SHARE_THIS: 'شارك هذا'
    },
    PROMOTION_FILTER: {
      FILTER_PROMOTIONS: 'تصفية العروض',
      LOCATION: 'الموقع',
      DATE_RANGE: 'نطاق التاريخ',
      PROMOTION_CATEGORY: 'فئة العرض',
      PROMOTION_STATUS: 'حالة العرض',
      APPLY_FILTER: 'تطبيق الفلتر'
    },
    ABOUT_US: {
      OUR_MISSION: 'مهمتنا',
      OUR_STORY: "قصتنا",
      READ_FULL_STORY: "اقرأ القصة الكاملة",
      OUR_LEADERSHIP: "قيادتنا",
      OUR_HERITAGE: "تراثنا",
      OUR_VISION: "رؤيتنا",
      OUR_VALUES: "قيمنا",
      PROFESSIONAL: "محترف",
      RLEATED: 'الصفحات المتعلقة بالمحتوى',
      RLEATED_CONTENT: 'المحتوى ذو الصلة',
    },
    FAHES_MEDIA_CENTER: {
      NEWS: 'الأخبار والإعلانات',
      MULTIMEDIA: 'الوسائط المتعددة',
      EVENTS: 'الأحداث',
      VIEW: 'رؤية الجميع',
      OUR_LASTEST_NEWS: 'آخر الأخبار والإعلانات لدينا',
      OUR_LASTEST_MULTIMEDIA: 'أحدث الوسائط المتعددة لدينا',
      OUR_LASTEST_EVENTS: 'أحدث الأحداث لدينا',
      READ_MORE: 'المزيد',
      READ_LESS: 'عرض أقل',
      SEARCH: 'البحث من هنا',
      FILTER: 'فلترة',
      LOAD_MORE: 'تحميل المزيد',
      MILESTONES: 'معالم',
      OF: 'الى',
    },
    PERIOD: {
      PM: 'مساءاً',
      AM: 'صباحاً',
    },
    MONTHS: {
      JAN: 'يناير',
      FEB: 'فبراير',
      MAR: 'مارس',
      APR: 'أبريل',
      MAY: 'مايو',
      JUN: 'يونيو',
      JUL: 'يوليو',
      AUG: 'أغسطس',
      SEP: 'سبتمبر',
      OCT: 'أكتوبر',
      NOV: 'نوفمبر',
      DEC: 'ديسمبر',

      JANUARY: "يناير",
      FEBRUARY: "فبراير",
      MARCH: "مارس",
      APRIL: "أبريل",
      // MAY: "مايو",
      JUNE: "يونيو",
      JULY: "يوليو",
      AUGUST: "أغسطس",
      SEPTEMBER: "سبتمبر",
      OCTOBER: "أكتوبر",
      NOVEMBER: "نوفمبر",
      DECEMBER: "ديسمبر"
    },
    TRAFFIC_SERVISE: {
      TITLE_STORY: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      BUTTON_STORY: 'MOI',
      STATION: 'خريطة المحطة',
      INSURANCE: 'تأمين',
      LOCATION: 'موقع',
      STATUS: 'حالة المحطة',
      STATION_CONTACT: 'تفاصيل جهات اتصال المحطة',
      TYPE: 'نوع المحطة',
      AVAILABLE: 'الخدمات المتاحة',
    },
    SERVICE_INSPESTION: {
      YOUR_BOOKING: 'تم تأكيد حجزك بنجاح',
      CONGRATS: 'تهانينا، لقد تم حجز التذاكر الخاصة بك بنجاح',
      ID: 'معرف تأكيد الحجز:',
      VIEW_SUMMARY: 'ملخص رأي',
      BOOKING_SUMMARY: 'ملخص الكتاب',
      ASSIGN: 'تعيين وظيفة',
      FEE: 'ضريبة تفتيش',
      APPOINTMENT: 'الرقم المرجعي للموعد ',
      NAME: 'الاسم المخصص',
      REMOVE: 'إزالة السيارة',
      SELECT: 'اختار',
      CANCEL: 'يلغي',
      CONFIRM: 'يتأكد',
      NO_OF: 'رقم المركبة:',
      APPLY: 'تنطبق على الجميع',
      BACK: 'العودة إلى التفتيش',
      DUE: 'التفتيش الواجب',
      CLEAR: 'امسح الكل',
      SORT: 'ترتيب حسب:',
      ONLY: 'إعادة الفحص مرة واحدة فقط مجانية',
      APPROX: 'السعر الإجمالي تقريبًا',
      LIST: 'التالي قائمة مركبات الفحص الواجب',
      DESCRIPTION: 'المركبات الفردية التي يقل عمرها عن 3 سنوات، لا تحتاج إلى فحص',
      BOOKING_CONFIREMD: 'تاكيد الحجز',
      REFERENCE: 'معلومات الحجز',
      VEHICLE_NAME: 'اسم المركبة',
      PLATE: 'طبق',
      AMOUNT: 'كمية',
      DATE: 'تاريخ ووقت الموعد',
      CHOOSE: 'اختر سيارتك',
      DATE_TIME: 'حدد التاريخ والوقت',
      INSPECTED_DATE: 'تاريخ التفتيش',
      INSPECTED_STATUS: 'حالة التفتيش',
      DATE_AND_TIME: 'التاريخ والوقت',
      PAYMENT: 'ملخص الدفع',
      NO_OF_CAR: 'عدد السيارات',
      PRICE_SELECTED: 'سعر السيارة المختارة',
      SUB_TOTAL: 'المجموع الفرعي',
    },
    MEDIA_TYPES: {
      ALL: 'الكل',
      VIDEOS: 'فيديوهات',
      GALLERIES: 'معارض الصور',
      IMAGES: 'صور',
      INFOGRAPHICS: 'إنفوجرافيك'
    },
    MEDIA_CENTER: {
      NEWS_AND_ANNOUNCMENTS: 'أخبار وإعلانات',
      MULTIMEDIA: 'وسائط متعددة',
      EVENTS: 'فعاليات',
      PUBLISHED: 'تم النشر:',
      START_DATE: 'تاريخ البدء:',
      END_DATE: 'تاريخ الانتهاء:',
      RELATED_NEWS: 'أخبار ذات صلة',
      RELATED_EVENTS: 'فعاليات ذات الصلة',
    },
    EVENT: {
      EVENT_CATEGORY: 'فئة الحدث',
      FILTER_THE_EVENT_BY: 'تصفية الأحداث حسب',
      EVENT_INFORMATION: 'معلومات الفعالية ',
    },
    MULTIMEDIA: {
      SEARCH_BY_NAME_DESCRIPTION: 'البحث بالاسم / الوصف',
      SEARCH_BY_NAME: 'البحث بالاسم'
    },
    BREADCRUMB: {
      CORPORATE_SOCIAL_RESPONSIBILITY: 'المسؤولية الاجتماعية للشركات',
      BECOME_A_SUPPLIER: 'كن موردًا',
      TENDER_REQUEST: 'طلب عطاء',
      TENDER_ALERT: 'تنبيه العطاء',
      TENDERS_AND_SUPPLIER: 'المناقصون والموردون',
      PAGES: 'الصفحات',
      SUCCESS: 'نجاح',
      MEDIA_CENTER: 'المركز الإعلامي',
      NEWS_AND_ANNOUNCEMENTS: 'الأخبار والإعلانات',
      EVENTS: 'الفعاليات',
      MULTIMEDIA: 'وسائط متعددة',
      VIDEOS: 'فيديوهات',
      GALLERIES: 'معارض الصور',
      IMAGES: 'صور',
      ENVIRONMENTAL_MANAGEMENT: 'الإدارة البيئية',
      PETROL_STATION_SAFETY_INSTRUCTIONS: 'تعليمات السلامة في محطات الوقود',
      INTEGRATED_MANAGEMENT_SYSTEM: 'نظام إدارة متكامل',
      AWARENESS_CAMPAIGN: 'حملة توعية',
      BRAND_GUIDELINES: 'إرشادات العلامة التجارية',
      COMPANY_PROFILE: 'ملف الشركة',
      HEALTH_AND_SAFETY: 'الصحة و السلامة',
      PROMOTIONS: 'العروض',
      FAHES: 'فاحص',
      CODE_OF_ETHICS: 'مدونة قواعد الأخلاق والسلوك',
      WHY_WOQOD: 'لماذا وقود',
      CAREERS: 'وظائف',
      WHAT_WE_DO: 'الذي نفعله',
      EQUALITY_DIVERSITY_INCLUSION: 'المساواة والتنوع والشمول',
      WHAT_WE_LOOK_FOR: 'ما نبحث عنه',
      INTERVIEW_TIPS: 'نصائح حول المقابلة',
      GET_NOTICED: 'جذب الانتباه',
      TALENT_DEVELOPMENT_STRATEGY: 'إستراتيجية تطوير المواهب',
      QATARAIZATION: 'التقطير',
      LIFE_AT_WOQOD: 'الحياة في وقود',
      WOQOD_CULTURE: 'ثقافة وقود',
      BENEFITS: 'الفوائد',
      TOP_TEN_REASON_TO_CHOOSE_WOQOD: 'أهم 10 أسباب لاختيار وقود',
      MEET_OUR_TEAM: 'قابل فريقنا',
      FIVE_WAYS_TO_HELP_YOU_FIND_THE_RIGHT_CAREER_PATH: 'خمس طرق لمساعدتك في العثور على المسار الوظيفي الصحيح',
      SEARCH_RESULT: 'نتائج البحث',
      FAHES_ABOUT_US: 'عن الشركة',
      FAHES_WHAT_WE_DO: 'ما نقوم به',
      FAHES_WHO_WE_ARE: 'من نحن',
      FAHES_CONTACT_US: 'تواصل معنا',
      SYSTEMS_AND_REGULATION: 'الأنظمة واللوائح',
      WOQODE_TAG: 'علامة ووقود',
      RETAIL: 'بيع بالتجزئة',
      AUTO_CARE_SERVICE: 'خدمة العناية بالسيارات',
      BUSINESS_WITH_WOQOD: 'الأعمال مع وقود',
      FAQ: 'الأسئلة الشائعة',
      SUSTAINABILITY: 'الاستدامة',
      VISION_MISSION_VALUES: 'الرؤية الرسالة القيم',
      ABOUT_US: 'معلومات عنا',
      KENAR_SHOP: 'متجر كنار',
      SIDRA: 'سيدرا',
      TENDER_DETAILS: 'تفاصيل المناقصة',
      TENDER_AND_SUPPLIER: 'العطاء والمورد',
      SHAFAF: 'شفاف',
      SERVICES: 'الخدمات',
      ERM: 'ERM',
      LUBRICANTS: 'Lubricants',
      CAREER_JOBS: 'التقدم للوظائف',
      LEADERSHIP: 'قيادتنا',
      BUSINESS_CONTINUITY_MANAGEMENT: 'إدارة استمرارية الأعمال',
      ENTERPRISE_RISK_MANAGEMENT: 'إدارة مخاطر المؤسسة',
      Document:'الوثائق',
      REQUEST_E_TAG:'طلب العلامة الإلكترونية'
    },
    CAREER_FILTER: {
      KEYWORD_SEARCH: 'بحث بالكلمات الدلالية',
      SELECT_LOCATION: 'اختر الموقع',
      SELECT_DEPARTMENTS: 'اختر الأقسام',
      FILTER_BY_TAGS: 'تصفية حسب العلامات',
      MORE_TAGS: 'علامات أكثر',
      FILTER_BY_SALARY_RANGE: 'تصفية حسب نطاق الراتب',
      FULL_TIME_ONLY: 'دوام كامل فقط',
      INSIDE_DOHA_QATAR: 'داخل الدوحة قطر',
      FILTER: 'منقي'
    },
    RANGE: {
      MIN_SALARY: 'الحد الأدنى للراتب',
      MAX_SALARY: 'الحد الأقصى للراتب'
    },
    CAREER_LISTING: {
      JOB_ID: 'معرف الوظيفة',
      INDUSTRY: 'الصناعة',
      JOB_INFORMATION: 'معلومات مهمة',
      JOB_TIME: 'وقت العمل',
      JOB_LOCATION: 'الموقع الحالي',
      CITY: 'مدينة',
      STATE: 'الولاية/المقاطعة',
      COUNTRY: 'دولة',
      SALARY: 'مرتب',
      ZIP: 'الرمز البريدي / الرمز البريدي',
      REQUIREMENTS: 'متطلبات',
      DETAILS: 'تفاصيل',
      INTERSTED: 'انا مهتم',
      REQUEST: 'طلب وثيقة',
      CAREER_JOB: 'مسمى وظيفي',
      SECOND_NAME: 'الاسم الثاني',
      NATIONALITY: 'جنسية',
      CV_FILE: 'ملف السيرة الذاتية',
      EDUCATION_DEGREE: 'المؤهل العلمي',
      FIELD: 'مجال الدراسة',
      CURRENT: 'حالة التوظيف الحالية',
      YEARS: 'سنوات من الخبرة',
      WORK: 'صناعة العمل الحالية / السابقة',
      EMPLOYER: 'صاحب العمل الحالي/السابق',
      UNVIERSITY: 'شهادة تعليمية',
      DESC: 'الوصف',
      JOB_CODE: 'رمز الوظيفة',
    },
    TENDER_DETAILS: {
      TENDER_INFORMATION: 'معلومات العطاء',
      S_NUMBER: 'الرقم التسلسلي للعطاء',
      TENDER_NUMBER: 'رقم المناقصة',
      TEBDER_CATEGORY: 'فئة المناقصة',
      FEE: 'مصاريف',
      SCOPE: 'مجال العمل'
    },
    PLURAL: {
      JOB: {
        ZERO: '0 وظائف',
        SINGULAR: 'وظيفة واحده',
        DUAL: 'وظيفتان',
        BETWEEN_THREE_NINE: '{{count}} وظائف',
        TEN: '{{count}} وظائف',
        MORE_THAN_TEN: '{{count}} وظيفة',
      },
      DAYS_LEFT: {
        ZERO: '{{count}} Days Left',
        SINGULAR: '{{count}} Day Left',
        DUAL: '{{count}} Days Left',
        BETWEEN_THREE_NINE: '{{count}} Days Left',
        TEN: '{{count}} Days Left',
        MORE_THAN_TEN: '{{count}} Days Left'
      }
    },
    ERRORS: {
      THIS_FIELD_IS_REQUIRED: 'هذه الخانة مطلوبة',
      YOUR_BROWSER_DOES_NOT_SUPPORT_HTML5_VIDEO: 'متصفحك لا يدعم فيديو HTML5.',
      Email: 'عنوان البريد الإلكتروني الذي أدخلته غير صالح',
      CR: 'رقم السجل التجاري الذي أدخلته غير صالح',
    },
    SIGN_IN: {
      FULL_ADDRESS: 'العنوان الكامل',
      SIGN_IN_WOQOD: 'تسجيل الدخول إلى وقود',
      ENTER_MOBILE_NUMBER: 'أدخل رقم الجوال',
      RESEND_IN: 'إعادة الإرسال في',
      RESEND: 'إعادة الإرسال',
      ENTER_VERIFICATION_CODE: 'أدخل رمز التحقق',
      LOGIN: 'تسجيل الدخول',
      OR: 'أو',
      CONTINUE_AS_A_GUEST: 'المتابعة كضيف',
      ENTER_YOUR_PROFILE_DETAILS: 'أدخل تفاصيل ملفك الشخصي',
      FULL_NAME: 'الاسم الكامل',
      EMAIL: 'البريد الإلكتروني',
      ADDRESS: 'العنوان',
      DATE_OF_BIRTH: 'تاريخ الميلاد',
      IAM_MAN: 'أنا رجل',
      IAM_WOMAN: 'أنا امرأة',
      CONTINUE: 'متابعة',
      PROTECTED_BY_RECAPTCHA_AND_SUBJECT_TO_BE: 'محمي بواسطة reCAPTCHA وخاضع لـ',
      WOQOD_PRIVACY_POLICY: 'سياسة الخصوصية لـ وقود',
      AND: 'و',
      TERMS_OF_SERVICE: 'شروط الخدمة',
      FIRST_NAME: 'الاسم الأول',
      LAST_NAME: 'الاسم الأخير',
      VERIFY: 'التحقق',
      QID: 'البطاقة الشخصية القطرية',
      QID_PASSPORT: 'البطاقة الشخصية القطرية/جواز السفر',
      POBOX: 'صندوق البريد',
      COUNTRY: 'البلد',
      CITY: 'مدينة',
      STREET: 'عنوان الشارع',
      GENDER: 'جنس',
      MALE: 'ذكر',
      FEMALE: 'أنثى'

    },
    SEARCH: {
      ALL_FAQS: "جميع الأسئلة الشائعة",
      WRITE_HERE: 'اكتب هنا..',
      KEYWORD_SUGGESTIONS: 'اقتراحات الكلمات الرئيسية',
      HISTORY: 'التاريخ',
      SEARCH: 'بحث',
      SEARCH_RESULT: 'نتائج البحث من',
      READ_MORE: 'اقرأ المزيد',
      FILTER_BY_CATEGORY: 'تصفية حسب الفئة',
      SELECT_CATEGORY: 'تحديد فئة',
      FILTER_BY_TAG: 'تصفية حسب العلامة'
    },
    SUPPLIER: {
      VIEW_FORM: 'مشاهدة النموذج',
      SUBSCRIBE_FOR_THE_TENDER_ALERTS: "اشترك للحصول على تنبيهات المناقصات",
      PLEASE_TICK_THE_BOX_BEFORE_SUBMITTING: "يرجى وضع علامة في المربع قبل الإرسال",
      COMPANY_INFORMATION: "معلومات الشركة",
      VENDOR_NAME: "اسم البائع",
      VENDOR_NUMBER: "رقم البائع",
      COMPANY_REGISTRATION_NUMBER_INTERNATIONAL_CORPORATE_ID: "رقم تسجيل الشركة / المعرّف الدولي للشركات",
      COMPANY_REGISTRATION_EXPIRY: "انتهاء تسجيل الشركة",
      COMPANY_CATEGORY: "فئة الشركة",
      COMPANY_SUB_CATEGORY: "فئة فرعية للشركة",
      DAREEBA_TIN: "الرقم الضريبي لـ داريبا",
      ICV_CERTIFICATE_NUMBER: "رقم شهادة ICV",
      ICV_CERTIFICATE_EXPIRY: "انتهاء شهادة ICV",
      ICV_SCORE: "درجة ICV",
      PRIMARY_CONTACT_NUMBER: "رقم الاتصال الأساسي",
      SECONDARY_CONTACT_NUMBER: 'رقم الاتصال الثانوي',
      CONFIRM_EMAIL: "تأكيد البريد الإلكتروني",
      AUTO_CARE_SUPPLIER: 'مورد العناية بالسيارات',
      AUTO_CARE_FOR_CORPORATES: 'الرعاية التلقائية للشركات',
      ALL: 'جميع الفئات'
    },
    RETAIL: {
      FUEL_RETAIL_PRICES: 'أسعار بيع الوقود بالتجزئة',
      BZS_LITER: 'بيسة / لتر',
      QR_LITER: 'ر.ق / لتر',
      BECOME_A_SUPPLIER_FOR_SIDRA: 'كن موردًا لمركز السدرة',
      BECOME_A_SUPPLIER_AUTO_CARE: 'كن موردًا للعناية بالسيارات',
      CONTACT_PERSON: 'جهة الاتصال',
      TELEPHONE_NUMBER: 'رقم الهاتف',
      PHONE_NUMBER: 'رقم الهاتف',
      MAILING_ADDRESS: 'عنوان المراسلات',
      EMAIL: 'البريد الإلكتروني',
      FAX_NUMBER: 'رقم الفاكس',
      OFFICE_ADDRESS: 'عنوان المكتب',
      FOR_ADDITIONAL_INFORMATIONS: 'لمزيد من المعلومات',
      VALID_COMPANY_REGISTRATION: 'تسجيل الشركة الصالح',
      YES: 'نعم',
      NO: 'لا',
      SAVE_AND_CONTINUE_LATER: 'حفظ والاستمرار لاحقًا',
      NEXT: 'التالي',
      BACK: 'إلى الخلف',
      YOUR_REMARKS: 'ملاحظاتك',
      PRODUCT_CATEGORY: 'فئة المنتج',
      PAYMENT_TERMS_NUMBER_OF_DAYS: 'شروط الدفع (عدد الأيام)',
      BOOK_NOW: 'احجز الآن',
      QID: 'البطاقة الشخصية القطرية',
      VEHICLE_PLATE_TYPE: 'نوع لوحة المركبة',
      VEHICLE_PLATE_NUMBER: 'رقم لوحة المركبة',
      VEHICLE_TYPE: 'نوع المركبة',
      TYPE: 'النوع',
      SAVED_VEHICLES: 'المركبات المحفوظة',
      MANUFACTURER_NAME: 'اسم الشركة المصنعة',
      PETROL_STATION: 'محطة بنزين',
      MODEL: 'نموذج',
      BEST_TIME_TO_CALL: 'أفضل وقت للمكالمه',
      CATEGORY_OF_BUSINESS: 'فئة الأعمال',
      EXPECTED_DATE_TO_START_BUSINESS_OPERATIONS: 'التاريخ المتوقع لبدء العمليات التجارية',
      VAIDATE: 'التحقق من صحة',
      PAYMENT_TERMS: 'شروط الدفع (عدد الأيام)',
      DELIVERY_FREQUENCEY: 'تكرار التسليم',
      ORDERING_LEAD_TIME: 'المهلة الزمنية للطلب',
      SCOPE_OF_DELIVERY: 'نطاق التسليم',
      APPROVE_FOR_LISTING: 'الموافقة على الإدراج',
      TIME: 'الوقت',
      PAYMENT: 'حدد أي خيارات دفع لمتابعة الدفع',
      SUBMIT: 'إرسال'
    },
    FAQ: {
      FAQS: "التعليمات",
      VIEW_ALL_FAQS: "عرض جميع الأسئلة الشائعة",
      ALL_CATEGORY: 'جميع الفئات'
    },
    TINDER: {
      VENDOR_NAME: 'اسم البائع',
      ADDRESS: 'عنوان',
      CONTACT_NAME: 'اسم جهة الاتصال',
      CONTACT: 'رقم الاتصال#',
      COLLECT_TINDER_DOCUMENT: 'جمع مستندات المناقصه',
      AWARD_TENDERS: 'مناقصات المكافأة',
      CLOSED_TENDERS: 'المناقصات المغلقة',
      OPEN_TENDERS: 'المناقصات المفتوحة',
      SEARCH_BY_AREA_NAME: 'البحث حسب اسم المنطقة',
      SR_NO: 'الرقم التسلسلي',
      TENDER_NO: 'رقم المناقصة',
      DESCRIPTION: 'الوصف',
      LAST_COLLECTION_DATE: 'تاريخ التجميع الأخير',
      BOND: 'ضمان',
      FEE: 'رسوم',
      CATEGORY: 'الفئة',
      REQUEST: 'طلب',
      VIEW_ALL_TINDERS: 'عرض جميع المناقصات',
      ACTIVE_TINDER: 'نشط المناقصات',
      TENDER_TITLE: 'عنوان العطاء',
      SERIEL_NUMBER: 'الرقم التسلسلي',
      INFORMATION: 'معلومات',
      SUCCESSFUL: 'مقدم عرض ناجح',
      DETAIL: 'عرض التفاصيل',
      CLOSING_DATE: 'تاريخ الإنتهاء',
      View_DETAIL: 'عرض المزيد',
      BID_CLOSING_DATE: 'تاريخ إغلاق العطاء',
    },
    TIMELINE: {
      HOW_TO_USE: 'تعرف كيفية استخدامها'
    },
    TABS: {
      INDIVIDUAL: 'فرد',
      CORPORATE: 'مؤسسة',
      PRODUCTS: 'منتجات',
      SERVICES: 'خدمات',
    },
    PRICE_TABLE: {
      PRICE_TABLE: 'جدول الأسعار',
      SEE_PRICES: 'انظر الأسعار',
      PER_TAG: 'لكل علامة'
    },
    BUSINESS: {
      INITIATE_CONTRACT: 'بدء العقد'
    },
    PRICES: {
      PRICES: 'الأسعار',
      VIEW_ALL_PRICES: 'عرض جميع الأسعار',
      BOOK_AN_APPOINTMENT: 'حجز موعد',
      PRICE_LIST: 'قائمة الأسعار',
      PRICE_IN: 'السعر في'
    },
    STEPS: {
      SHOP_INFORMATION: 'معلومات المتجر',
      PERSONAL_INFORMATION: 'معلومات شخصية',
      BUSINESS_INFORMATION: 'معلومات العمل',
      ATTACHMENTS: 'المرفقات',
      SUBMISSION: 'تقديم',
      GENERAL_DETAILS: 'تفاصيل عامة',
      VEHICLE_DETAILS: 'تفاصيل المركبة',
      BOOK_APPOINTMENT: 'حجز موعد',
      TERMS_AND_CONDITIONS: 'الشروط والأحكام',
      PAYMENT: 'الدفع',
    },
    E_TAG: {
      WALLET_LIMIT: 'حد المحفظة',
      REQUEST_A_NEW_E_TAG: 'طلب وسم إلكتروني جديد',
      IF_YOU_HAVE_ALREADY_INSTALLED_A_TAG_AND_WANT_TO_REPLACE_IT_KINDLY: 'إذا كنت قد قمت بتركيب وسم بالفعل وترغب في استبداله، يُرجى',
      CLICK_HERE: 'النقر هنا',
      NAME: 'الاسم',
      QID: 'الهوية الشخصية',
      INVOICE: 'الفاتورة',
      TRACKING: 'التتبع',
      EMAIL: 'البريد الإلكتروني',
      MOBILE_NUMBER: 'رقم الجوال',
      NATIONAL_ADDRESS: 'العنوان الوطني',
      ZONE_NUMBER: 'رقم المنطقة',
      STREET_NUMBER: 'رقم الشارع',
      BUILDING: 'المبنى',
      UNIT: 'الوحدة',
      PO_BOX: 'صندوق بريد',
      FAX_NUMBER: 'رقم الفاكس',
      VEHICLE_DETAILS: 'تفاصيل المركبة',
      VEHICLE_STATUS: 'حالة المركبة',
      MODEL: 'الطراز',
      MODEL_YEAR: 'سنة الصنع',
      MANUFACTURE_TO_BE_BRAND: 'الشركة المصنعة (العلامة التجارية)',
      EXPIRY_DATE: 'تاريخ الانتهاء',
      SEQUENCE_NUMBER: 'رقم التسلسل',
      INSURANCE_COMPANY_NAME: 'اسم شركة التأمين',
      START_DATE: 'تاريخ البدء',
      REGISTRATION: 'التسجيل',
      STATUS: 'الحالة',
      VEHICLE_ID_NUMBER: 'رقم هوية المركبة',
      INSURANCE_EXPIRATION_DATE: 'تاريخ انتهاء التأمين',
      PHONE_NUMBER: 'رقم الهاتف',
      SAVE_VEHICLE: 'حفظ المركبة',
      NUMBER_OF_VEHICLES: 'عدد المركبات',
      NUMBER_OF_TAGS: 'عدد الوسوم',
      TOTAL_PRICE: 'السعر الإجمالي',
      ADD_BENEFICIARY: 'إضافة مستفيد',
      CONFIRM: 'تأكيد',
      SELECT_CAR: 'اختر السيارة',
      ADD_A_NEW_CAR: 'إضافة سيارة جديدة',
      CAR: 'سيارة',
      SEARCH_BY_NUMBER_PLATE: 'البحث بواسطة لوحة الرقم',
      RE_INSPECTION: 'إعادة فحص',
      ADD: 'إضافة',
      DETAILS: 'التفاصيل',
      QID_CR: 'الهوية الشخصية / السجل التجاري',
      UPLOAD_FILE: 'تحميل الملف',
      ESTIMARA: 'الاستمارة',
      INSTALLATION_COST: 'تكلفة التركيب',
      TAG_ID: 'معرف الوسم',
      TAG_FEE: 'رسوم الوسم',
      COST_OF_INSTALLATION: 'تكلفة التركيب',
      TOTAL: 'الإجمالي',
      BOOK_DETAILS: 'تفاصيل الحجز',
      PAYMENT_METHOD: 'طريقة الدفع',
      CREDIT_CARD: 'بطاقة ائتمانية',
      KFAST: 'كي-فاست',
      AMEX: 'أميكس',
      OTHER_METHODS: 'طرق دفع أخرى',
      CREDIT_LIMIT: 'الحد الائتماني',
      NET_BANKING: 'الخدمات المصرفية عبر الإنترنت',
      E_Wallet: 'المحفظة الإلكترونية',
      APPLE_WALLET: 'محفظة آبل',
      NEW_CARD: 'بطاقة جديدة',
      NAME_ON_THE_CARD: 'الاسم على البطاقة',
      CARD_NUMBER: 'رقم البطاقة',
      VALID_TILL: 'صالحة حتى',
      CVV: 'الرمز CVV',
      COST_OF_TAG: 'تكلفة العلامة + رسوم التثبيت',
      PLATE_NUMBER: 'رقم اللوحة',
      PLATE_TYPE: 'نوع اللوحة',
      TRANSACTION: 'حد المعاملة (QRA أو لتر)*',
      FUEL_TYPE: 'نوع الوقود *',
      FUEL_LIMIT: 'مدة الحد الأقصى للوقود *',
      MAJOR: 'اللون الرئيسي',
      VEHICLE_INFORMATION: 'معلومات السيارة',
      VEHICLE_INSURANCE: 'تفاصيل تأمين المركبات',
      PHONE: 'رقم التليفون *',
      FAMILY: 'العلاقة العائلية بين حساب العميل ومالك السيارة',
      NUMBER_PLATE: 'لوحة الأرقام',
      MANUFACTOR: 'الصانع',
      DAYS: 'الأيام المتبقية',
      BOOKED_ON: 'تم الحجز',
      BOOKED_DETAILS: 'تفاصيل الحجز',
      Name_AR: 'الاسم بالعربية',
      NUMBER_OF_TAG: 'عدد العلامات',
      BY_NAME: 'البحث حسب اسم الخدمة',
      SERVICE_NAME: 'اسم الخدمة',
      STATION: 'المحطة',
      Max:'الحد الأقصى للحجم',
      MB:'ميغابايت'
    },
    SHOP: {
      BACK: 'عودة',
      SUBMIT: 'إرسال',
      VEHICLE_TYPE: 'نوع المركبة',
      EXISTING_TENANT: 'مستأجر حالي',
      I_ACCEPT_AND_AGREE_TO_THE_FOLLOWING_TERMS_AND_CONDITIONS: 'أوافق وأقبل على الشروط والأحكام التالية:',
      RENT_A_SHOP: 'استئجار متجر',
      BACK_TO_AVAILABLE_SHOPS_PAGE: 'العودة إلى صفحة المتاجر المتاحة',
      DEADLINE: 'الموعد النهائي',
      LOCATION: 'الموقع',
      FIRST_NAME: 'الاسم الأول',
      STATION_NAME: 'اسم المحطة',
      NUMBER_APPLICANTS: 'عدد المتقدمين حتى الآن',
      SHOP_PRE: 'إيجار المحل شهري (ر.ق) للمتر المربع',
      SHOP_SIZE: 'مساحة المحل بالمتر المربع',
      NUMBER_OF_SHOPS: 'عدد المحلات التجارية المطلوبة *',
      OPTION: 'خياري',
      LIST_LOCATIONS_IF_ANY: 'قائمة المواقع (إن وجدت)',
      REQUIRED_INFORMATION: 'المعلومات المطلوبة',
      NEXT: 'التالي',
      MIDDLE_NAME: 'الاسم الأوسط',
      LAST_NAME: 'اسم العائلة',
      MOBILE_NUMBER: 'رقم الجوال',
      OFFICE_TELEPHONE_NUMBER: 'رقم هاتف المكتب',
      EMAIL: 'البريد الإلكتروني',
      COMPANY_NAME: 'اسم الشركة',
      DATE: 'التاريخ',
      WHY_WOQOD_SHOULD_CONSIDER_YOU_FOR_LEASING_A_SHOP: 'لماذا يجب أن تنظر وقود في استئجار متجر لك؟',
      WHAT_ADDITIONAL_VALUE_PRODUCTS_SERVICES_YOUR_BUSINESS_WILL_OFFER_TO_WOQOD_CUSTOMERS: 'ما القيمة الإضافية (المنتجات / الخدمات)<br/>التي ستقدمها شركتك لعملاء وقود؟',
      WILL_YOU_HAVE_A_PARTNER: 'هل ستكون لديك شريك؟',
      COMPANY_TURNOVER_IN_QATAR: 'إجمالي مبيعات الشركة في قطر',
      NUMBER_OF_EMPLOYEES: 'عدد الموظفين',
      VALIDATE: 'تحقق',
      CR_NUMBER: 'رقم السجل التجاري',
      NUMBER_OF_BRANCHES_IN_GSS: 'عدد الفروع في دول مجلس التعاون الخليجي',
      NUMBER_OF_BRANCHES_IN_GCC: 'عدد الفروع في دول مجلس التعاون الخليجي',
      NUMBER_OF_BRANCHES_IN_QATAR: 'عدد الفروع في قطر',
      TERMS_AND_CONDITIONS: 'الشروط والأحكام',
      TERMS_AND_CONDITIONS_FOR_BALLOTING: 'الشروط والأحكام للقرعة',
      TERMS_AND_CONDITIONS_FOR_PROPERTY_RENTALS: 'الشروط والأحكام لتأجير العقارات',
      PAYMENT_SUCCESSFUL: 'تم الدفع بنجاح',
      FUEL_TYPE_CHANGED: 'تم تغيير نوع الوقود',
      LIMITED_HOURS: 'ساعات محدودة',
      FRANCHISE_COMPANY: 'شركة الامتياز',
      COMMERCIAL_REGISTRATION_CR_COPY: 'السجل التجاري (نسخة السجل التجاري)',
      COPY_OF_COMPUTER_CARD_OR_TRADING_LICENSE: 'نسخة من بطاقة الكمبيوتر أو رخصة التجارة',
      BANK_ACCOUNT_DETAILS_IN_THE_COMPANY_LETTERHEAD: 'تفاصيل الحساب البنكي في ترويسة الشركة',
      LETTER_FROM_BANK_CONFIRMING_THE_ACCOUNT: 'خطاب من البنك يؤكد الحساب',
      COPY_OF_OWNERS_ID: 'نسخة من هوية المالك',
      COMPANY_PROFILE: 'ملف تعريف الشركة',
      BRANDING_PROFILE: 'ملف تعريف العلامة التجارية',
      I_AGREE: 'موافق',
      START_DATE: 'تاريخ البدء',
      END_DATE: 'تاريخ الانتهاء',
      SUPPLIER_NAME: 'اسم المورد',
      CONTACT_PERSON: 'الشخص الذي يمكن الاتصال به',
      TRADE: 'رخصة تجارية',
      TAX_CARD: 'بطاقة ضريبية',
      AUTO_CARE: 'خدمات العناية بالسيارات',
      PRODUCT: 'وصف المنتج',
      INSURANCE_COMPANY: 'قائمة شركات التأمين',
      INSURANCE: 'شركة التأمين',
      CR_START_DATE: 'تاريخ بدء السجل التجاري',
      CR_END_DATE: 'تاريخ انتهاء السجل التجاري',
    },
    UPLOADER: {
      ATTACH: 'إرفاق'
    },
    DAYS: {
      MON: "الاثنين",
      TUE: "الثلاثاء",
      WED: "الأربعاء",
      THU: "الخميس",
      FRI: "الجمعة",
      SAT: "السبت",
      SUN: "الأحد"
    },
    INSPECTION_FILTER: {
      FULL_DAY_FULL_WEEK: '24 ساعة / 7 أيام في الأسبوع',
      CHOOSE_STATION: 'اختر المحطة',
      CHOOSE_ANY: 'اختر أيًا',
      CHOOSE_STATUS: 'اختر الحالة',
      SELECT_YOUR_PETROL_STATION_TYPE: 'اختر نوع محطة الوقود الخاصة بك',
      SELECT_YOUR_PETROL_STATION_STATUS: 'اختر حالة محطة الوقود الخاصة بك',
      SELECT_BY_SERVICE_AVAILABLE: 'اختر حسب الخدمة المتاحة',
      SEARCH_LOCATIONS: 'البحث عن المواقع',
      EG: 'على سبيل المثال',
      WORKING_HOURS: 'ساعات العمل',
      SORT_BY: 'فرز حسب',
      SORT_BY_NEAREST_STATIONS: 'الترتيب حسب أقرب المحطات',
      OPEN_24_HOURS: 'مفتوح 24 ساعة',
      DIRECTION: 'الاتجاه',
    },
    TIME: {
      CHOOSE_TIME: 'اختر الوقت'
    },
    WOQODE_TAG: {
      STEP: 'الخطوة',
      HOW_TO_TOP_UP: 'كيفية تعبئة الرصيد؟',
    },
    KENAR: {
      DEADLINE: 'الموعد النهائي',
      RENT_SHOP: 'إستأجر المتجر',
      SEARCH_BY_TITLE: 'البحث حسب العنوان',
      SUBMIT_INTEREST_IN_LEASING_A_SHOP: 'تقديم الاهتمام بتأجير متجر'
    },
    SIDRA: {
      VISIT_SIDRA_WEBSITE: 'زيارة موقع السدرة',
      SHOP_NOW: 'تسوق الآن'
    },
    RENT_SHOP_TERMS_CONDITION: {
      CHECKBOX1: 'سأقوم أنا (مقدم الطلب) بتقديم شيك إداري / (ضمان بنكي) قابل للاسترداد بقيمة 10,000 ريال قطري لكل متجر، صادر لصالح وقود في (برج وقود، الخليج الغربي، الطابق السادس عشر، قسم البيع بالتجزئة) خلال 3 أيام عمل من تاريخ تقديم الطلب. يوم تقديم الطلب للمشاركة في عملية الاقتراع في المتجر. سيكون لوقود الحق المطلق في مصادرة مبلغ الضمان، في حالة رفض مقدم الطلب الاستيلاء على المحلات التجارية المخصصة له.',
      CHECKBOX2: 'لن يكون لمقدم الطلب الحق في تأجير محل الكنار من الباطن.',
      CHECKBOX3: 'لن يسمح لمقدم الطلب بتغيير الملكية أو طبيعة العمل.',
      HOMEPAGE: 'لمواصلة إعادة الشحن، فإنك توافق على الشروط والأحكام',
    },
    PRODUCT_AND_SERVICE: {
      // LUBRICANTS: 'مواد التشحيم',
      WOQODE: 'خدمة',
      FUEL: 'الوقود والتجزئة',
      WAQOD: 'العناية بالسيارات',
      READ_MORE: 'اقرأ أكثر',
      KINAR: 'كينار',
      SIDRA: 'سيدرا',
      SHAFAF: 'شفاف',
      BULK: 'الوقود السائب',
      FAHES: 'فاحص فحص المركبات',
      DISTRIBUTION: 'توزيع الوقود',
      GAS: 'توزيع الغاز',
      AVIATION: 'إمدادات الطيران',
      LUBRICANTS: 'زيوت التشحيم وقود',
      VIEW_OTHER_PRODUCTS: 'عرض منتجات اخرى',
      ENTER_YOUR_QUERIES: 'أدخل معلومات الاستعلام',
      FOR_MORE_INFORMATION: 'للمزيد من المعلومات'
    },
    SHAFAF: {
      RATES: 'امتيازات',
      LPG_ACCESSORIES: 'ملحقات LPG',
      VISIT_SHAFAF: 'قم بزيارة موقع شفاف',
      WHERE_TOBUY_SHAFAF: 'مكان الشراء شفاف',
      VIEW: 'عرض القائمة',
    },
    WOQOD_APP: {
      APP: 'الخدمات الإلكترونية المتوفرة على تطبيق الهاتف المحمول لتلبية كافة احتياجات السوق الخدمية!',
      DOWNLOAD: 'حمل تطبيق وقود الآن !!'
    },
    FUELMODAL: {
      TITLE: 'أسعار الوقود لهذا العام',
      MONTH: 'شهر',
      GAS_SUPER: 'بنزين سوبر (95)',
      GAS_PRE: 'البنزين الممتاز (91)',
      DIESEL: 'ديزل',
    },
    MODALWOQOODE: {
      TITLE: 'خدمات',
      INDIVIDUAL: 'العملاء الأفراد',
      PRICE: 'السعر بالريال القطري',
      LEVEL: 'مستوى',
      CLICKC: 'انقر هنا للشركات WOQODE',
      CLICKI: 'انقر هنا للحصول على WOQODE للأفراد',
      CORPORATE: 'عملاء مساهمين',
      TAGS: 'العلامات',
      FROM: 'من',
      TO: 'الى',
    },
    FILTER_DDL: {
      CONTACT_NUMBER: 'ترتيب حسب رقم الاتصال تصاعديا',
      CONTACT_NUMBER_DESC: 'ترتيب حسب رقم الاتصال تنازليا',
      TITLE: 'ترتيب حسب عنوان',
      STATION: 'ترتيب حسب المحطة تصاعدي',
      STATION_DESC: 'ترتيب حسب المحطة تنازليا',
      ADDRESS: 'ترتيب حسب العنوان تصاعديا',
      ADDRESS_DESC: 'ترتيب حسب العنوان تنازليا',
      CONTACT_NAME: 'ترتيب حسب اسم جهة الاتصال تصاعديًا',
      CONTACT_NAME_DESC: 'ترتيب حسب اسم جهة الاتصال تنازليا',
      TITLE_DESC: 'ترتيب حسب العنوان تنازليا',
      TITLE_ASC: 'ترتيب حسب العنوان تصاعدي',
    },
    TENDER_MODAL: {
      CR_CERTIFICATE: 'شهادة السجل التجاري',
      ADDRESS: 'العنوان الوطني',
    },
    PLACEHOLDER: {
      DECLARATION: 'بيان',
      PRODUCT_DESCRIPTION: 'وصف المنتج',
      QID: '6789 6789 22',
      EMAIL: 'البريد الإلكتروني',
      SUPPLIER_NAME: 'محطة بنزين عين سنان',
      CONTACT_PERSON: 'جون أحمد',
      CR_NUMBER: '22445',
      S_DATE: '20 / 2 / 2020',
      E_DATE: '20 / 2 / 2020',
      F_NAME: 'جون',
      M_NAME: 'صموئيل',
      L_NAME: 'جوني',
      MOBILE: '٩٤٧ ٤٥٦٧٨٩٦٧',
      COMPANY_NAME: 'woqood company',
      COMPANY_NAME_AR: ' شركة وقود',
      NUMBER: '20',
      NUMBER_GCC: '68',
      EMPLOYE_NUMBERR: '368',
      LIST_LOCATIONS: 'أدخل مواقع القائمة (إن وجدت)',
      CUSTOMER: 'أدخل تفاصيل حول القيمة الإضافية أو المنتجات أو الخدمات التي ستقدمها خطط عملك لعملاء وقود.',
      LEASING: 'يرجى ذكر الأسباب التي تدفع وقود إلى التفكير في استئجار متجر ما.',
      TURNOVER: "أدخل حجم مبيعات شركتك في قطر (ريال قطري)*",
      NATIONALTIY: 'قطري',
      EDUCATION: "درجة البكالوريوس",
      FIELD: 'علم الكمبيوتر',
      CURRENT: 'موظف',
      YEARS: '7',
      INDUSTRY: 'التصنيع',
      EMPOYLER: 'شركة ABC',
      FULL_NAME: 'عبدالفادر أحمد',
      FEEDBACK_TITLE: 'عنوان ملاحظاتك',
      FEEDBACK_TITLE_AR: 'عنوان ملاحظاتك باللغة العربية',
      COMPANY: 'شركة وقود',
      COMMENTS: 'أدخل تعليقاتك هنا...',
      ZONE: 'أدخل رقم المنطقة (مثال: 123)',
      BUILDING: 'أدخل اسم المبنى أو رقمه',
      UNIT: 'أدخل اسم الوحدة أو رقمها',
      NAME_EN: 'ex: John Samuel',
      NAME_AR: 'عبدالفادر أحمد',
      ENTER_LOCATION: 'أدخل الموقع',
      SHOW_ALL: 'عرض الكل',
      ACTIVE_ONLY: 'نشط فقط',
      NEARSET: 'الأقرب',
      NEWLY_ADDED: 'مضاف حديثا',
      BY_STATION: 'البحث حسب اسم المحطة',
      DEADLINE: 'ترتيب الموعد النهائي ',
      LASTEST_ADDED: 'ترتيب المضاف حديثا',
      SIZE_ASC: 'ترتيب الحجم تصاعدي',
      SIZE_DESC: 'ترتيب الحجم تنازليا',
      DATE_FROM: 'من تاريخ',
      DATE_TO: 'الى تاريخ',
      CORORATE_ID: 'أدخل رقم تسجيل الشركة/معرف الشركة الدولي',
      CERTEFICATE_NUMBER: 'ABC-9876543',
      ICV_SCORE: '48 نقطة',
      DATE: 'يوم-شهر-سنة',
      COUNTRY: 'الدولة',
      GENDER: 'السيد / السيدة',
      TITLE: 'عنوان'
    },
    PAYMENT: {
      ID: 'رقم المعاملة',
      DATE: 'تاريخ المعاملة',
      METHODS: 'طريقة الدفع',
      TOTAL: 'إجمالي مبلغ تعبئة الرصيد',
      CONGRATS: 'تهانينا',
      INTO_WALLET: 'تضاف إلى محفظتك',
      YOU_HAVE: 'لديك',
      AVAILABLE: 'متوفر في المحفظة',
      PAYMENT_FAILED: "لم نتمكن من معالجة دفعتك"
    },
    INVESTOR: {
      RELATIONS: 'علاقات المستثمرين',
      EXECUTIVE_MANAGEMENT: 'الإدارة التنفيذية',
    }
  }
};
