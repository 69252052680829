<footer class="border-t border-solid border-[#0000001f]">
    <div class="section flex flex-col lg:flex-row py-11">
        <div class="w-fulll lg:w-1/4">
            <div class="w-full relative z-10">
                <a appIntersectionObserver class="fade-in" href="https://www.google.com/">
                    <img src="assets/svgs/logo.svg" class="button-wraper-inner w-[118px]" alt="FOM" />
                </a>
                <p appIntersectionObserver class="fade-in text-[#2e2b28] mt-[29px]">
                    We believe that every small
                    detail matters,
                    and we
                    strive
                    to turn your
                    wedding dream into a stunning
                    reality. Let us be your partner in the most important moments of your life</p>
            </div>
        </div>
        <div class="w-fulll lg:w-1/4 flex justify-center">
            <div appIntersectionObserver class="fade-in">
                <p class=" font-normal  text-[#2e2b28] relative z-10">Farhet Omry</p>
                <ul class="flex flex-col gap-0.5 mt-[12px] relative z-10">
                    <li>
                        <a href="https://www.google.com/" class="text-[#2e2b28]">
                            Contact us
                        </a>
                    </li>
                    <li>
                        <a href="https://www.google.com/" class="text-[#2e2b28]">
                            About us
                        </a>
                    </li>
                    <li>
                        <a href="https://www.google.com/" class="text-[#2e2b28]">
                            Site map
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="w-fulll lg:w-1/4 flex justify-center">
            <div appIntersectionObserver class="fade-in">
                <p class=" font-normal  text-[#2e2b28] relative z-10">Help and Support</p>
                <ul class="flex flex-col gap-0.5 mt-[12px] relative z-10">
                    <li>
                        <a href="https://www.google.com/" class="text-[#2e2b28]">
                            Terms and conditions
                        </a>
                    </li>
                    <li>
                        <a href="https://www.google.com/" class="text-[#2e2b28]">
                            Privacy Center
                        </a>
                    </li>
                    <li>
                        <a href="https://www.google.com/" class="text-[#2e2b28]">
                            Cookie Setting
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="w-fulll lg:w-1/4 flex justify-center">
            <div class="flex flex-col items-center fade-in relative z-10" appIntersectionObserver>
                <p class="text-[#2e2b28]">Download App</p>
                <img src="https://seeklogo.com/images/Q/qr-code-logo-27ADB92152-seeklogo.com.png"
                    class="button-wraper-inner h-[92px] mt-[12px]" alt="FOM" />
            </div>
        </div>
    </div>
    <div class="py-[19px] relative">
        <div class="striped-background bg-primary"></div>
        <div class="section flex flex-col lg:flex-row justify-between z-10 relative">
            <div appIntersectionObserver class="fade-in flex flex-col lg:flex-row gap-[14px]">
                <div>
                    <p class="text-white">Follow us on social media</p>
                </div>
                <div>
                    <ul class="flex flex-row gap-1">
                        <li>
                            <a href="https://www.google.com/"
                                class="bg-white rounded-full w-[22px] h-[22px] flex items-center justify-center">
                                <app-svg src="assets/svgs/x.svg" class="mt-[-3px]" customClass=""
                                    label="title_goas_here" [stretch]="false" width="12" fill="#802d3d"></app-svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.google.com/"
                                class="bg-white rounded-full w-[22px] h-[22px] flex items-center justify-center">
                                <app-svg src="assets/svgs/x.svg" class="mt-[-3px]" customClass=""
                                    label="title_goas_here" [stretch]="false" width="12" fill="#802d3d"></app-svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.google.com/"
                                class="bg-white rounded-full w-[22px] h-[22px] flex items-center justify-center">
                                <app-svg src="assets/svgs/x.svg" class="mt-[-3px]" customClass=""
                                    label="title_goas_here" [stretch]="false" width="12" fill="#802d3d"></app-svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.google.com/"
                                class="bg-white rounded-full w-[22px] h-[22px] flex items-center justify-center">
                                <app-svg src="assets/svgs/x.svg" class="mt-[-3px]" customClass=""
                                    label="title_goas_here" [stretch]="false" width="12" fill="#802d3d"></app-svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.google.com/"
                                class="bg-white rounded-full w-[22px] h-[22px] flex items-center justify-center">
                                <app-svg src="assets/svgs/x.svg" class="mt-[-3px]" customClass=""
                                    label="title_goas_here" [stretch]="false" width="12" fill="#802d3d"></app-svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <p appIntersectionObserver class="fade-in text-white text-right">©
                    2024 Farhet Omry. All Rights
                    Reserved.</p>
            </div>
        </div>
    </div>
</footer>