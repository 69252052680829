<header #header class="relative" [class.sticky-header]="isStickyEnabled">
    <div class="tt-header fixed w-full z-[100] left-0 top-0 h-[85px]
    " style="mix-blend-mode: multiply;">
        <div class="bg-layer w-full h-full bg-transparent transition-all">
        </div>
    </div>
    <nav class="tt-header fixed w-full h-auto z-[100] left-0 top-0">
        <div class="">
            <div class="section transition-all flex flex-wrap items-center justify-between pt-[40px] pb-10">
                <div class="items-center justify-between hidden w-full lg:flex lg:w-1/3" id="navbar-cta">
                    <ul appIntersectionObserver
                        class="fade-in left-menu flex flex-col p-4 lg:p-0 mt-4 gap-4 lg:flex-row lg:mt-0 whitespace-nowrap">
                        <li class="button-wraper" data-element-scale="1.1">
                            <!-- <span class="text-white">                            forceStickyEnabled:{{forceStickyEnabled}}
                                <br>
                                isStickyEnabled:{{isStickyEnabled}}</span> -->
                            <a href="#" class="block py-2 px-3 lg:p-0 text-[#2e2b28] button-wraper-inner transition-all"
                                aria-current="page">Home</a>
                        </li>
                        <li class="button-wraper" data-element-scale="1.1">
                            <a href="#"
                                class="block py-2 px-3 lg:p-0 text-[#2e2b28] button-wraper-inner transition-all">About</a>
                        </li>
                        <li class="button-wraper" data-element-scale="1.1">
                            <button type="button" (click)="navigateToListing()"
                                class="block py-2 px-3 lg:p-0 text-[#2e2b28] button-wraper-inner transition-all">Services</button>
                        </li>
                        <li class="button-wraper" data-element-scale="1.1">
                            <a href="#"
                                class="block py-2 px-3 lg:p-0 text-[#2e2b28] button-wraper-inner transition-all">Contact</a>
                        </li>
                    </ul>
                </div>
                <button appIntersectionObserver data-element-scale="1.1" type="button" (click)="navigate()"
                    class="fade-in button-wraper flex items-center lg:w-1/3 lg:justify-center">
                    <img [src]="'assets/svgs/' + (isStickyEnabled ? 'logo-sticky' : 'logo') + '.svg'"
                        class="button-wraper-inner h-[42px]" alt="FOM" />
                </button>
                <div class="flex gap-4 lg:w-1/3 lg:justify-end fade-in" appIntersectionObserver>
                    <ul
                        class="hidden lg:flex flex-col p-4 lg:p-0 mt-4 lg:flex-row lg:mt-0 items-center gap-4 whitespace-nowrap">
                        <li class="button-wraper" data-element-scale="1.1">
                            <a href="#" class="block py-2 px-3 lg:p-0 text-white button-wraper-inner"
                                aria-current="page">
                                <app-svg src="assets/svgs/search.svg" customClass="" label="title_goas_here"
                                    [stretch]="false" width="14" stroke="#ffffff"></app-svg>

                            </a>
                        </li>
                        <li class="button-wraper" data-element-scale="1.1">
                            <a href="#" class="block py-2 px-3 lg:p-0 text-white button-wraper-inner">Language
                                (en)</a>
                        </li>
                        <li class="button-wraper" data-element-scale="1.1">
                            <a href="#" class="block py-2 px-3 lg:p-0 text-white button-wraper-inner">Sign
                                in</a>
                        </li>
                    </ul>
                    <div class="button-wraper" data-element-scale="1.1">
                        <button type="button"
                            class="button-wraper-inner hidden lg:block text-primary bg-white focus:ring-4 focus:outline-none focus:ring-blue-300  font-normal rounded-lg px-4 py-2 text-center">Subscribe</button>
                    </div>
                    <button data-collapse-toggle="navbar-cta" type="button"
                        class="inline-flex items-center p-2 w-10 h-10 justify-center text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                        aria-controls="navbar-cta" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 17 14">
                            <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </nav>
</header>